.quiz-question-collapsed-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(118, 118, 118, 0.06);
  padding: 10px 15px;
  margin-top: 24px;
  cursor: pointer;

  .quiz-question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;

    .question-label {
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(27, 27, 27, 0.6);
      margin: 0;
    }

    .question-text {
      font-size: 1rem;
      font-weight: 400;
      color: rgba(27, 27, 27, 0.9);
      margin: 0;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .delete-question {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    .trash-icon {
      font-size: 1.1rem;
      color: #767676;
    }

    .delete-text {
      font-size: 0.875rem;
      color: rgba(27, 27, 27, 0.6);
      white-space: nowrap;
      margin: 0 0 0 10px;
    }
  }
}
