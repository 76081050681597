.video-note-wrapper {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-auto-flow: column;
  column-gap: 15px;

  .note-icon {
    font-size: 1.2rem;
    color: #ff2116;
  }

  .note-title {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .remove-icon {
    font-size: 1rem;
    cursor: pointer;
  }
}
