.player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 52.65%; /* Player ratio: 100 / (1280 / 720) */
  background: #010001;

  .player-error {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    margin: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .post-video-options-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-auto-flow: column;
    column-gap: 48px;
  }

  .error-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: #fff;
    font-size: 1rem;
  }

  .blank-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .no-videos-text {
      font-size: 2.25rem;
      font-weight: 700;
      color: #888585;
      margin: 0;
    }
  }
}

@media screen and (max-width: 1100px) {
  .player-wrapper {
    .post-video-options-wrapper {
      column-gap: 40px;
    }
  }
}

@media screen and (max-width: 700px) {
  .player-wrapper {
    .post-video-options-wrapper {
      column-gap: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .player-wrapper {
    .post-video-options-wrapper {
      column-gap: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .player-wrapper {
    .post-video-options-wrapper {
      column-gap: 15px;
    }
  }
}
