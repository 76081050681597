.footer {
  background-color: #1b1b1b;
  width: 100%;
  margin-top: auto;
  padding: 20px 0;
  z-index: 2;

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 45px;

    .footer-div-1 {
      display: grid;
      justify-content: flex-start;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20%;

      .about-kooledge-link {
        text-decoration: none;
        color: #fff;
        font-size: 1rem;
        font-weight: 400;
      }

      .about-kooledge-link:hover {
        color: #f9a159;
      }
    }

    .footer-div-2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);

      .footer-copyright {
        color: rgba(249, 249, 249, 0.6);
        font-size: 1rem;
        font-weight: 400;
        margin: 0 25% 0 0;
        white-space: nowrap;
        text-align: center;
      }

      .footer-socials-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .footer-socials-icon {
          font-size: 1.5rem;
          color: rgba(249, 249, 249, 0.6);
          cursor: pointer;
        }

        .middle {
          margin: 0 25px;
        }

        .footer-socials-icon:hover {
          color: #f9a159;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .footer .footer-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1080px) {
  .footer {
    padding: 40px 0;

    .footer-container {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 50px;

      .footer-div-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .about-kooledge-link {
          margin-top: 35px;
        }

        .about-kooledge-link:hover {
          color: #f9a159;
        }
      }

      .footer-div-2 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        .footer-copyright {
          margin: 40px 0 0 0;
          white-space: unset;
        }
      }
    }
  }
}
