.profile-settings-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 50%;

  .profile-settings-grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 40px;
    row-gap: 25px;
    width: 100%;
    margin-bottom: 25px;

    .avatar-input-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      grid-column: 1;
      width: 100%;
      margin-bottom: 6px;

      .avatar-image-wrapper {
        width: 48px;
        height: 48px;

        .avatar-image {
          width: 48px;
          height: 100%;
          border-radius: 50%;
        }

        .avatar-image-overlay {
          display: none;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.8);

          .delete-avatar-icon {
            color: #fff;
          }
        }

        .avatar-image:hover {
          cursor: pointer;

          .avatar-image-overlay {
            display: flex;
          }
        }

        .avatar-image-none {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 100%;
          border-radius: 50%;
          background: #f5f5f5;
        }
      }

      .avatar-input-text {
        width: 100%;
        margin-left: 16px;

        .set-avatar {
          font-size: 1.125rem;
          font-weight: 500;
          color: #4f4f4f;
          margin: 0;
        }

        .avatar-input-wrapper {
          font-size: 0.75rem;
          font-weight: 500;
          color: #2d9cdb;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .MuiFormControl-root {
    border-radius: 10px;
    width: 100%;
    height: 64px;

    .MuiFilledInput-root {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 10px;
      height: 100%;
      background-color: #f5f5f5;

      .MuiFilledInput-input {
        padding: 27px 16px 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .MuiFormHelperText-root {
      color: #fa5646;
      border: none !important;
      margin: 8px 16px 0;
    }

    .Mui-focused {
      background: #fff;
      border: 1.2px solid #1a1a1a;
    }

    .MuiInputLabel-root {
      margin-bottom: 10px;
      color: #8e8e8e;
      border: none;
      background-color: transparent;
    }

    .MuiInputLabel-filled {
      transform: translate(16px, 25px) scale(1);
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(16px, 14px) scale(0.75);
    }

    .MuiFilledInput-underline:before {
      border: none;
    }

    .MuiFilledInput-underline:after {
      border: none;
    }

    .MuiInputAdornment-root {
      .MuiIconButton-root {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            fill: #ababab;
          }
        }
      }
    }
  }

  .auth-card-input-focused {
    height: 29px;
    background: #fff;
    border: 1.2px solid #1a1a1a;
    border-radius: 10px;
  }

  .MuiFilledInput-root {
    border-radius: 10px;
    height: 100%;

    .MuiFilledInput-input {
      padding: 27px 16px 8px;
      height: 29px;
      border-radius: 10px;
    }
  }

  .change-password-button {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    cursor: pointer;
  }

  .save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    height: 48px;
    padding: 0 70px;
    margin: 40px 0 60px auto;
    border: none;
    outline: none;
    border-radius: 50px;
    background: #364a4e;
    cursor: pointer;
  }

  .save-button:hover {
    background-color: #283f44;
  }
}

@media screen and (max-width: 1200px) {
  .profile-settings-wrapper {
    max-width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .profile-settings-wrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .profile-settings-wrapper {
    .profile-settings-grid {
      .avatar-input-wrapper {
        grid-column: 1;
      }
    }

    .save-button {
      width: 100%;
    }
  }
}
