.add-topic-content-card {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 239px;
  background: #c4c4c4;
  cursor: pointer;

  .add-topic-content-card-text {
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    margin: 10px 0 0 0;
  }
}

.add-topic-content-card-show {
  display: flex;
}
