.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-top: 84px;
  background: #e1e2e3;
  padding: 120px 20px;

  .forgot-password-link {
    font-size: 0.875rem;
    font-weight: 400;
    color: #8e8e8e;
    align-self: flex-end;
    text-decoration: none;
    margin-top: 16px;
  }
}

.auth-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  width: 480px;
  background: #fff;
  border: 1px solid rgba(26, 26, 26, 0.05);
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  padding: 40px;
  z-index: 1;

  .auth-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;

    .auth-card-header-text {
      font-size: 1.75rem;
      font-weight: 700;
      text-align: center;
      color: #000;
      margin: 0;
    }

    .auth-card-hearder-icon {
      color: #ababab;
      cursor: pointer;
    }
  }

  .auth-card-header-multiline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .auth-card-header-multiline-heading-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .auth-card-header-multiline-text {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
      color: #8e8e8e;
      margin: 8px 0 0 0;
    }
  }

  .auth-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    .auth-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      .auth-card-input {
        margin-top: 16px;
      }

      .MuiFormControl-root {
        border-radius: 10px;
        width: 100%;
        height: 64px;

        .MuiFilledInput-root {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 10px;
          height: 100%;
          background-color: #f5f5f5;

          .MuiFilledInput-input {
            padding: 27px 16px 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        .MuiFormHelperText-root {
          color: #fa5646;
          border: none !important;
          margin: 8px 16px 0;
        }

        .Mui-focused {
          background: #fff;
          border: 1.2px solid #1a1a1a;
        }

        .MuiInputLabel-root {
          margin-bottom: 10px;
          color: #8e8e8e;
          border: none;
          background-color: transparent;
        }

        .MuiInputLabel-filled {
          transform: translate(16px, 25px) scale(1);
        }

        .MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: translate(16px, 14px) scale(0.75);
        }

        .MuiFilledInput-underline:before {
          border: none;
        }

        .MuiFilledInput-underline:after {
          border: none;
        }

        .MuiInputAdornment-root {
          .MuiIconButton-root {
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                fill: #ababab;
              }
            }
          }
        }
      }

      .auth-card-input-focused {
        height: 29px;
        background: #fff;
        border: 1.2px solid #1a1a1a;
        border-radius: 10px;
      }
    }
  }

  .auth-card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .auth-card-button-dark {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #364a4e;
      border-radius: 46px;
      height: 56px;
      width: 100%;
      outline: none;
      border: none;
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      margin-bottom: 16px;
    }

    .auth-card-button-dark:hover {
      background: #283f44;
    }

    .auth-card-button-dark-no-margin {
      margin: 0 !important;
    }

    .auth-card-button-no-margin:hover {
      background: #283f44;
    }

    .auth-card-button-light {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 46px;
      border: 2px solid #364a4e;
      height: 56px;
      width: 100%;
      outline: none;
      font-size: 1rem;
      font-weight: 600;
      color: #364a4e;
      cursor: pointer;
    }

    .auth-card-button-light:hover {
      background-color: #d74941;
      color: #fff;
      border: none;
    }
  }
}
