.my-quiz-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .my-quiz-checkbox {
    .MuiCheckbox-root {
      position: absolute;
      top: -19px;
      right: -19px;
      padding: 0;

      .my-quiz-check-icon {
        font-size: 2.2rem;
        border-radius: 50px;
        color: #219653;
        background-color: #fff;
      }
    }
  }

  .search-checkbox {
    .MuiCheckbox-root {
      position: absolute;
      top: -12px;
      right: -12px;
      padding: 0;

      .search-check-icon {
        font-size: 1.7rem;
        border-radius: 50px;
        color: #219653;
        background-color: #fff;
      }
    }
  }

  .my-quiz-card-thumbnail {
    width: 100%;
    height: 160px;

    .edit-content-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .trash-icon {
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }

      .edit-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .edit-icon {
          font-size: 1.4rem;
          font-weight: 500;
          color: #fff;
        }

        .edit-content-text {
          font-size: 1.25rem;
          font-weight: 700;
          color: #fff;
          margin: 0;
          padding-left: 11px;
        }
      }
    }
  }

  .my-quiz-card-thumbnail:hover {
    .edit-content-overlay {
      display: flex;
    }
  }

  .search-card-thumbnail {
    width: 100%;
    height: 130px;
    cursor: pointer;
  }

  .my-quiz-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 11.5px;
    width: 100%;

    .my-quiz-card-title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      .my-quiz-card-title {
        font-size: 1rem;
        font-weight: 700;
        color: #000;
        width: 100%;
        margin: 0;
      }
    }
  }
}
