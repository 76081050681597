.quiz-result-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 25px;

  .quiz-result-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .result-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      border-radius: 4px;
      white-space: nowrap;

      .status-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }

      .status-text {
        font-size: 0.875rem;
        font-weight: 600;
        margin: 0;
        padding-left: 12px;
      }
    }

    .result-status.pass {
      background: #ddffec;
      border: 2px solid #219653;

      .status-dot {
        background: #219653;
      }

      .status-text {
        color: #219653;
      }
    }

    .result-status.fail {
      background: #ffdfdc;
      border: 2px solid #fa5646;

      .status-dot {
        background: #fa5646;
      }

      .status-text {
        color: #fa5646;
      }
    }
  }

  .quiz-result-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 20px 60px;

    .result-icon {
      font-size: 4rem;
      background: #fff;
    }

    .result-icon.pass {
      color: #219653;
    }

    .result-icon.fail {
      color: #fa5646;
    }

    .result-text {
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      text-align: center;
      margin: 24px 0 0 0;
    }

    .replacement-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .replacement-heading {
        font-size: 1rem;
        font-weight: 400;
        color: #000;
        margin: 30px 0;
      }

      .replacement-buttons-wrapper {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
        column-gap: 16px;

        .replacement-button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0 40px;
          height: 48px;
          border: none;
          outline: none;
          border-radius: 50px;
          white-space: nowrap;
          cursor: pointer;
        }

        .replacement-button.no {
          color: #747474;
          background: rgba(27, 27, 27, 0.1);
        }

        .replacement-button.no:hover {
          background: rgba(27, 27, 27, 0.15);
        }

        .replacement-button.yes {
          color: #fff;
          background: #30beee;
        }

        .replacement-button.yes:hover {
          background: #11b7ee;
        }
      }
    }

    .download-certificate-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: #fff;
      margin-top: 30px;
      padding: 0 40px;
      height: 48px;
      border: none;
      outline: none;
      border-radius: 50px;
      background: #364a4e;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
    }

    .download-certificate-button:hover {
      background-color: #283f44;
    }
  }

  .quiz-result-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    margin-top: auto;

    .retry-quiz-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      color: #747474;
      height: 48px;
      padding: 0 58px;
      border: none;
      outline: none;
      border-radius: 50px;
      background: rgba(27, 27, 27, 0.1);
      white-space: nowrap;
      cursor: pointer;
    }

    .retry-quiz-button:hover {
      background: rgba(27, 27, 27, 0.15);
    }

    .finish-quiz-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      color: #fff;
      height: 48px;
      padding: 0 58px;
      border: none;
      outline: none;
      border-radius: 50px;
      background: #30beee;
      white-space: nowrap;
      cursor: pointer;
      margin-left: 16px;
    }

    .finish-quiz-button:hover {
      background: #11b7ee;
    }
  }
}
