.post-video-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  background: #fff;
  border-radius: 36px;
  white-space: nowrap;
  cursor: pointer;

  .option-icon {
    width: 22%;
  }

  .option-text {
    font-size: 1.1875rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin: 5px 0 0 0;
  }
}

@media screen and (max-width: 1100px) {
  .post-video-option {
    width: 110px;
    height: 110px;
    border-radius: 30px;

    .option-text {
      font-size: 1.125rem;
    }
  }
}

@media screen and (max-width: 900px) {
  .post-video-option {
    width: 100px;
    height: 100px;
    border-radius: 25px;

    .option-text {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .post-video-option {
    width: 80px;
    height: 80px;
    border-radius: 20px;

    .option-text {
      font-size: 0.875rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .post-video-option {
    width: 60px;
    height: 60px;
    border-radius: 15px;

    .option-text {
      font-size: 0.625rem;
      margin: 2px 0 0 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .post-video-option {
    width: 40px;
    height: 40px;
    border-radius: 10px;

    .option-text {
      font-size: 0.375rem;
      margin: 2px 0 0 0;
    }
  }
}
