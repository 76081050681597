.home-banner-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .home-banner-item-overlay {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      89.86deg,
      #2324247e 50%,
      rgba(54, 74, 78, 0) 99.88%,
      rgba(54, 74, 78, 0.1) 99.88%
    );

    .home-banner-item-details {
      display: grid;
      justify-content: flex-start;
      align-items: center;
      grid-auto-flow: row;
      grid-auto-rows: minmax(min-content, max-content);
      width: 50%;

      .title {
        font-weight: 700;
        color: #fff;
        margin: 0;
      }

      .description {
        font-weight: 700;
        color: rgba(249, 249, 249, 0.9);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .play-flow-button-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .play-flow-button {
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          border-radius: 30px;
          background-color: #f9a159;
          border: none;
          outline: none;

          .play-flow-button-icon {
            color: #fff;
          }

          .play-flow-button-text {
            color: #fff;
            font-weight: 600;
            text-align: center;
          }
        }

        .play-flow-button:hover {
          background-color: #fa9746;
        }
      }

      .creator-details-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .creator-profile-image {
          border-radius: 50%;
          object-fit: cover;
        }

        .creator-name {
          font-weight: 700;
          color: #fff;
          margin: 0;
        }
      }
    }
  }
}
