.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-top: 84px;
  background: #e1e2e3;
  padding: 120px 20px;

  .forgot-password-header-success {
    justify-content: center;
  }

  .forgot-password-body-success-text {
    font-size: 1rem;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin: 4px 0 0 0;
  }

  .back-to-sign-in-button {
    margin: 0 !important;
  }
}
