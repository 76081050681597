.new-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;

  .new-comment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;

    .comment-input {
      width: 100%;
      background: #fff;
      border-radius: 8px;

      .MuiInputBase-root {
        padding: 12px;

        .MuiInputBase-input {
          font-size: 0.9375rem;
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
          border-radius: 8px;
          padding: 0 !important;
        }
      }
    }

    .new-comment-buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 15px 0;
      width: 100%;

      .timestamp-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiFormControlLabel-root {
          margin: 0;

          .MuiButtonBase-root {
            padding: 0;

            .MuiIconButton-label {
              width: 16px;
              height: 16px;

              .MuiSvgIcon-root {
                width: 16px;
                height: 16px;
              }
            }
          }

          .MuiFormControlLabel-label {
            font-size: 0.8125rem;
            font-weight: 400;
            color: #555555;
            padding: 0 0 0 5px;
          }
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: #30beee;
        }

        .timestamp-text {
          font-size: 0.875rem;
          font-weight: 400;
          color: #fff;
          background: #30beee;
          border-radius: 8px;
          padding: 4px 8px;
          margin: 0 0 0 6px;
        }
      }

      .post-comment-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        height: 37px;
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
        border: none;
        border-radius: 8px;
        background: #f9a159;
        box-sizing: border-box;
        white-space: nowrap;
      }

      .post-comment-button:hover {
        background: #fa9746;
      }

      .post-comment-button:disabled {
        background: #d4d4d4;
      }
    }
  }
}
