.arrow-up {
  position: absolute;
  top: 67px;
  right: 61px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 999;
}

.profile-menu {
  position: absolute;
  background-color: #fff;
  top: 77px;
  right: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 267px;
  z-index: 999;

  .profile-menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .profile-menu-name {
      color: #000;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      margin: 0;
    }

    .profile-menu-email {
      color: rgba(27, 27, 27, 0.6);
      font-size: 0.875rem;
      font-weight: 400;
      text-align: center;
      margin: 0;
    }
  }

  .profile-menu-link {
    color: rgba(27, 27, 27, 0.9);
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
  }

  .profile-menu-link:hover {
    text-decoration: none;
  }

  .profile-menu-logout {
    color: #fe416d;
    margin-bottom: 0;
    cursor: pointer;
  }

  .profile-menu-login {
    color: #26ba61;
    margin-bottom: 0;
    cursor: pointer;
  }

  .profile-menu-divider {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
  }

  .profile-menu-item {
    margin-top: 16px;
  }
}

.profile-menu-mobile {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .profile-menu-profile-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 12px;

    .profile-menu-profile-image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }

    .profile-menu-user-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      width: 100%;

      .profile-menu-user-name {
        color: #000;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        margin: 0;
        width: 100%;
        text-align: start;
      }

      .profile-menu-user-email {
        color: rgba(27, 27, 27, 0.6);
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        margin: 0;
        width: 100%;
        text-align: start;
      }
    }
  }

  .new-flow-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    height: 48px;
    white-space: nowrap;
    margin-right: 24px;
    border-radius: 47px;
    background-color: #f9a159;
    cursor: pointer;

    .new-flow-button-icon {
      color: #fff;
      font-size: 1.2rem;
    }

    h3 {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
      margin: 0 0 0 14px;
    }
  }

  .MuiPaper-elevation1 {
    background: rgba(249, 249, 249, 0.9);
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    margin-top: 16px;

    .MuiInput-root {
      .MuiInput-input {
        font-size: 1rem;
        font-weight: 400;
        color: #1b1b1b, 60%;
        padding: 6px 0;
      }
    }

    .MuiIconButton-root {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: unset;
        }
      }
    }
  }

  .profile-menu-link {
    color: rgba(27, 27, 27, 0.9);
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
  }

  .profile-menu-link:hover {
    text-decoration: none;
  }

  .profile-menu-logout {
    color: #fe416d;
    margin-bottom: 0;
    cursor: pointer;
  }

  .profile-menu-login {
    color: rgba(27, 27, 27, 0.9);
    margin-bottom: 0;
    cursor: pointer;
  }

  .profile-menu-divider {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
  }

  .profile-menu-item {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1300px) {
  .arrow-up {
    right: 36px;
  }

  .profile-menu {
    right: 20px;
  }
}
