.comment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;

  .user-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      object-fit: cover;
    }

    .name-wrapper {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 6px;

      .name {
        font-size: 1rem;
        font-weight: 600;
        color: #222222;
        margin: 0 0 0 10px;
      }

      .owner-name {
        color: #30beee;
      }

      .comment-timestamp {
        font-size: 0.75rem;
        font-weight: 400;
        color: #555555;
        margin: 0;
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content {
      display: flex;
      align-items: center;
      font-size: 0.9375rem;
      font-weight: 400;
      color: #555555;
      background: #fff;
      border-radius: 8px;
      border-top-left-radius: 0;
      margin: 0 0 0 48px;
      padding: 12px;
      width: 100%;

      .video-timestamp {
        color: #fff;
        background: #30beee;
        border-radius: 8px;
        padding: 4px 8px;
        margin: 0 10px 0 0;
        cursor: pointer;
      }

      .video-timestamp:hover {
        background: #11b7ee;
      }
    }

    .comment-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3px 0 0 48px;
      width: 100%;

      .show-replies-button {
        font-size: 0.625rem;
        font-weight: 500;
        color: #f9a159;
        background: none;
        border: none;
        outline: none;
        text-transform: uppercase;
        white-space: nowrap;
        margin-bottom: 5px;

        .show-replies-icon {
          font-size: 0.5rem;
          margin-left: 4px;
          transition: 0.2s ease-in-out;
        }
      }

      .comment-buttons-wrapper {
        display: grid;
        justify-content: flex-end;
        align-items: center;
        grid-auto-flow: column;
        column-gap: 6px;
        width: 100%;

        .delete-button {
          font-size: 0.625rem;
          font-weight: 550;
          color: #d61a3c;
          background: none;
          border: none;
          outline: none;
          text-transform: uppercase;

          .trash-icon {
            font-size: 0.75rem;
            font-weight: 500;
            color: #555555;
            cursor: pointer;
          }
        }

        .reply-button {
          font-size: 0.625rem;
          font-weight: 500;
          color: #3d3d3d;
          background: none;
          border: none;
          outline: none;
          text-transform: uppercase;
        }
      }
    }
  }

  .comment-replies-grid {
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    padding-left: 48px;
    width: 100%;
    margin-top: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.reply {
  width: 70%;
}
