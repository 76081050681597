.custom-modal {
  .modal-dialog {
    max-width: 800px;

    .modal-content {
      border: none;
      border-radius: 0;

      .suggest-video-player {
        position: relative;

        .close-modal-icon {
          position: absolute;
          font-size: 2.2rem;
          color: #d74941;
          background-color: #fff;
          border-radius: 50%;
          top: -15px;
          right: -15px;
          z-index: 1;
          cursor: pointer;
        }
      }

      .custom-modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 280px;
        padding: 48px;
        border-top: 5px solid #c5322a;

        .custom-modal-title {
          font-size: 2rem;
          font-weight: 700;
          color: #000;
          margin: 0;
        }

        .custom-modal-body {
          font-size: 1.125rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.6);
          width: 100%;
          margin: 20px 0 30px;
        }

        .custom-modal-footer {
          display: flex;
          justify-content: flex-end;
          align-self: flex-end;
          flex: 1;
          width: 100%;

          .custom-modal-custom-button {
            display: flex;
            justify-content: center;
            align-content: center;
            align-self: flex-end;
            padding: 0 40px;
            height: 48px;
            border-radius: 42px;
            background-color: #c5322a;
            cursor: pointer;

            .custom-modal-custom-button-text {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.125rem;
              font-weight: 700;
              color: #fff;
              margin: 0;
            }
          }

          .custom-modal-custom-button:hover {
            background-color: #be1f16;
          }

          .custom-modal-custom-button.topic {
            background-color: #30beee;
          }

          .custom-modal-custom-button.topic:hover {
            background-color: #11b7ee;
          }

          .custom-modal-custom-button.flow {
            background-color: #f9a159;
          }

          .custom-modal-custom-button.flow:hover {
            background-color: #fa9746;
          }

          .custom-modal-cancel-button-text {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            font-size: 1.25rem;
            font-weight: 700;
            color: rgba(27, 27, 27, 0.6);
            margin: 0 12px 0 44px;
            height: 48px;
            cursor: pointer;
          }
        }
      }

      .custom-modal.topic {
        border-top: 5px solid #30beee;
      }

      .custom-modal.flow {
        border-top: 5px solid #f9a159;
      }
    }
  }
}
