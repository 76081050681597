#my-profile-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1b1b1b;
  opacity: 0.6;
  z-index: 8;
}

.my-profile-side-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  position: fixed;
  background: #e1e2e3;
  padding: 45px;
  width: 350px;
  height: 100vh;
  z-index: 1;

  .my-profile-side-menu-arrow {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45px;
    right: -12px;
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    .arrow-icon {
      font-size: 1rem;
      color: #e1e2e3;
    }
  }

  .my-profile-side-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #e1e2e3;
    width: 100%;
    height: 100%;

    .personal-info-section {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;
      margin-bottom: 48px;

      .personal-info-section-row-1 {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;

        .profile-image-wrapper {
          position: relative;
          width: 75px;
          height: 75px;

          .profile-image {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 0.3px solid #d4d4d4;
            object-fit: cover;
          }

          .settings-icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -4px;
            right: -4px;
            background: #000;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            cursor: pointer;

            .settings-icon {
              font-size: 1rem;
              color: #fff;
            }
          }
        }

        .data-sum-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .data-sum {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1a1a1a;
            margin: 0;
          }

          .data-title {
            font-size: 0.875rem;
            font-weight: 400;
            color: #8e8e8e;
            margin: 0;
          }
        }

        .videos-sum-wrapper {
          margin-left: -30px;
        }
      }

      .personal-info-section-row-2 {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 2px;

        .user-full-name {
          font-size: 1.5rem;
          font-weight: 700;
          color: #1a1a1a;
          margin: 0;
        }

        .user-email {
          font-size: 1rem;
          font-weight: 400;
          color: #8e8e8e;
          margin: 0;
        }
      }
    }

    .menu-list-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 48px;

      .menu-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 10px 24px;
        cursor: pointer;

        .menu-list-item-title {
          font-size: 1rem;
          font-weight: 400;
          color: #1a1a1a;
          margin: 0;
          pointer-events: none;
        }

        .menu-list-item-sum {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 29px;
          height: 29px;
          border-radius: 50%;
          font-size: 1rem;
          font-weight: 400;
          color: #1a1a1a;
          margin: 0;
          pointer-events: none;
        }
      }

      .menu-list-item-selected {
        border-left: 6px solid #f9a159;
        background: #f5f5f5;
        padding: 10px 24px 10px 18px;

        .menu-list-item-title {
          font-weight: 600;
        }

        .menu-list-item-sum {
          font-weight: 600;
          color: #fff;
          background: #364a4e;
        }
      }
    }

    .logout-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      background: #fff;
      cursor: pointer;

      .logout-icon {
        font-size: 1.1rem;
        color: #fa5646;
      }

      .logout-button-text {
        font-size: 1rem;
        font-weight: 400;
        color: #fa5646;
        margin: 0 0 0 12px;
      }
    }
  }
}

.my-profile-side-menu-wrapper.active {
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
}

@media screen and (max-width: 900px) {
  .my-profile-side-menu-wrapper {
    position: fixed;
    z-index: 8;
    top: 84px;
    left: -40%;
    width: 45%;
    max-width: 45%;
    height: 100vh;
    transition: all 0.5s ease;

    .my-profile-side-menu-arrow {
      display: flex;
    }
  }
}

@media screen and (max-width: 700px) {
  .my-profile-side-menu-wrapper {
    left: -50%;
    width: 55%;
    max-width: 55%;
  }
}

@media screen and (max-width: 600px) {
  .my-profile-side-menu-wrapper {
    left: -90%;
    width: 95%;
    max-width: 100%;
  }
}
