.update-password-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-card {
    .close-modal-icon {
      position: absolute;
      font-size: 2.2rem;
      color: #d74941;
      background-color: #fff;
      border-radius: 50%;
      top: -15px;
      right: -15px;
      z-index: 1;
      cursor: pointer;
    }
  }

  .update-password-header-success {
    justify-content: center;
  }

  .update-password-body-success-text {
    font-size: 1rem;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin: 4px 0 0 0;
  }
}

.update-password-modal {
  .modal-dialog {
    max-width: fit-content;

    .modal-content {
      position: relative;
    }
  }
}
