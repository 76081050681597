.search-arrow-up {
  position: absolute;
  top: 48px;
  left: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 999;
}

.search-suggestions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 58px;
  left: 0;
  width: 518px;
  background: #fff;

  .search-suggestion {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 10px 20px;
    cursor: pointer;

    .search-icon {
      font-size: 1rem;
    }

    .search-suggestion-text {
      font-size: 1.125rem;
      font-weight: 400;
      color: #1b1b1b;
      margin: 0 0 0 25px;
    }
  }

  .search-suggestion:hover {
    background: #eaebeb;
  }
}

@media screen and (max-width: 1200px) {
  .search-suggestions-wrapper {
    width: 418px;
  }
}
