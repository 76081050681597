.my-profile-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  padding: 23px 60px 23px 410px;
  box-shadow: -1px 0px 0px #efefef;

  .my-profile-buttons-wrapper {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    margin-bottom: 40px;

    .my-profile-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(27, 27, 27, 0.6);
      padding: 0 16px;
      border: 1px solid rgba(27, 27, 27, 0.6);
      border-radius: 100px;
      height: 37px;
      background: #fff;
      outline: none;
      white-space: nowrap;
    }

    .my-profile-button:hover {
      border: 1px solid #f9a159;
      color: #f9a159;
    }
  }

  .my-profile-dashboard-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

    .content-heading-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 24px;

      .content-heading {
        font-size: 1.25rem;
        font-weight: 700;
        color: #000;
        margin: 0;
      }
    }

    .content-list-grid {
      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      row-gap: 40px;
      margin: 10px 0 60px;
      width: 100%;
    }

    .content-list-grid.video {
      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      row-gap: 40px;
      margin: 0 0 60px;
      width: 100%;

      .my-video-card {
        border-radius: 3px;

        .my-video-card-thumbnail {
          border-radius: 3px;
          height: 180px;
        }
      }
    }

    .content-list-grid.quiz {
      margin: 0 0 60px;

      .my-quiz-card {
        border-radius: 3px;

        .my-quiz-card-thumbnail {
          border-radius: 3px;
          height: 180px;
        }
      }
    }

    .my-certifications-section,
    .video-suggestions-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .certifications-wrapper,
      .video-suggestions-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: 0 0 50px;

        .certifications-heading {
          font-size: 1rem;
          font-weight: 700;
          color: #000;
          margin: 0 0 20px;
        }

        .video-suggestions-heading {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1rem;
          font-weight: 700;
          color: #000;
          margin: 0;

          span {
            display: flex;
            justify-content: center;
            align-items: center;

            .dot-icon {
              font-size: 0.5rem;
              margin-right: 10px;
            }
          }
        }

        .content-list-grid.certificate,
        .content-list-grid.video-suggestions {
          display: grid;
          align-items: flex-start;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 40px;
          row-gap: 40px;
          width: 100%;
          margin: 0;
        }

        .content-list-grid.video-suggestions {
          row-gap: 20px;
        }

        .content-list-grid.video-suggestions {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .no-content {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      margin-bottom: 40px;
    }
  }
}

@media screen and (min-width: 2200px) {
  .my-profile-dashboard {
    .my-profile-dashboard-content {
      .content-list-grid,
      .content-list-grid.video,
      .content-list-grid.quiz {
        grid-template-columns: repeat(5, 1fr);

        .home-flow-wrapper {
          .home-flow-thumbnail {
            height: 180px;
          }
        }
      }

      .content-list-grid.video {
        .my-video-card {
          .my-video-card-thumbnail {
            height: 180px;
          }
        }
      }

      .content-list-grid.quiz {
        .my-quiz-card {
          .my-quiz-card-thumbnail {
            height: 180px;
          }
        }
      }

      .my-certifications-section {
        .certifications-wrapper {
          .content-list-grid.certificate {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }

      .video-suggestions-section {
        .video-suggestions-wrapper {
          .content-list-grid.video-suggestions {
            grid-template-columns: repeat(5, 1fr);

            .my-video-card {
              .my-video-card-thumbnail {
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 2200px) {
  .my-profile-dashboard {
    .my-profile-dashboard-content {
      .content-list-grid,
      .content-list-grid.video,
      .content-list-grid.quiz {
        grid-template-columns: repeat(4, 1fr);

        .home-flow-wrapper {
          .home-flow-thumbnail {
            height: 180px;
          }
        }
      }

      .content-list-grid.video {
        .my-video-card {
          .my-video-card-thumbnail {
            height: 180px;
          }
        }
      }

      .content-list-grid.quiz {
        .my-quiz-card {
          .my-quiz-card-thumbnail {
            height: 180px;
          }
        }
      }

      .my-certifications-section {
        .certifications-wrapper {
          .content-list-grid.certificate {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      .video-suggestions-section {
        .video-suggestions-wrapper {
          .content-list-grid.video-suggestions {
            grid-template-columns: repeat(4, 1fr);

            .my-video-card {
              .my-video-card-thumbnail {
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .my-profile-dashboard {
    padding: 20px 60px 20px 410px;

    .my-profile-dashboard-content {
      .content-list-grid,
      .content-list-grid.video,
      .content-list-grid.quiz {
        grid-template-columns: repeat(2, 1fr);
      }

      .my-certifications-section {
        .certifications-wrapper {
          .content-list-grid.certificate {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }

      .video-suggestions-section {
        .video-suggestions-wrapper {
          .content-list-grid.video-suggestions {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .my-profile-dashboard {
    padding: 20px 50px 20px 85px;
  }
}

@media screen and (max-width: 600px) {
  .my-profile-dashboard {
    padding: 20px 40px 20px 55px;

    .my-profile-buttons-wrapper {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }

    .my-profile-dashboard-content {
      .content-list-grid,
      .content-list-grid.video,
      .content-list-grid.quiz {
        grid-template-columns: repeat(1, 1fr);
      }

      .video-suggestions-section {
        .video-suggestions-wrapper {
          .content-list-grid.video-suggestions {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}
