.navbar-menu-mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  padding: 30px;
  height: 100vh;
  top: 0;
  right: -30%;
  opacity: 0.8;
  z-index: 100;
  background-color: #fff;
  transition: all 0.5s ease;

  .menu-icon-times {
    font-size: 1.8rem;
    color: #1b1b1b;
    cursor: pointer;
  }

  .side-menu-item {
    margin-bottom: 16px;
  }

  .new-flow-button-wrapper:hover {
    background-color: #fa9746;
  }
}

.navbar-menu-mobile.active {
  right: 0;
  opacity: 1;
  transition: all 0.5s ease;
}

@media screen and (max-width: 960px) {
  .navbar-menu-mobile {
    width: 35%;
    right: -35%;
  }
}

@media screen and (max-width: 800px) {
  .navbar-menu-mobile {
    width: 40%;
    right: -40%;
  }
}

@media screen and (max-width: 700px) {
  .navbar-menu-mobile {
    width: 45%;
    right: -45%;
  }
}

@media screen and (max-width: 600px) {
  .navbar-menu-mobile {
    width: 100%;
    right: -100%;
  }
}
