@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro';
}

#root {
  overflow-x: hidden;
}

.my-container {
  width: 100%;
  padding: 0 120px;
}

.error-message {
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: #fa5646;
  display: block;
}

.error-message::first-letter {
  text-transform: capitalize;
}

.success-message {
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: #26ba61;
  display: block;
}

.delete-topic-icon {
  position: absolute;
  font-size: 2.2rem;
  color: #d74941;
  background-color: #fff;
  border-radius: 50%;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
