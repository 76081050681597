.flow-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 84px;
  margin-bottom: 60px;

  .flow-page-heading-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    margin: 40px 0 18px;

    .flow-page-edit-flow-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      border: 1px solid #000;
      border-radius: 47px;
      cursor: pointer;

      .edit-icon {
        color: #1b1b1b;
        font-size: 1rem;
      }

      .flow-page-edit-flow {
        font-size: 0.875rem;
        font-weight: 600;
        text-align: center;
        padding-left: 13px;
        margin: 0;
        color: #1b1b1b;
      }
    }

    .flow-page-edit-flow-button:hover {
      background-color: #364a4e;
      border: none;

      .edit-icon {
        color: #fff;
      }

      .flow-page-edit-flow {
        color: #fff;
      }
    }

    .edit-mode-button {
      background-color: #364a4e;
      border: none;

      .edit-icon {
        color: #fff;
      }

      .flow-page-edit-flow {
        color: #fff;
      }
    }

    .edit-mode-button:hover {
      background-color: #283f44;
    }

    .flow-page-delete-flow-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      margin-left: 12px;
      border-radius: 47px;
      background-color: #d74941;
      cursor: pointer;

      .delete-icon {
        font-size: 1.2rem;
        color: #fff;
      }

      .flow-page-delete-flow {
        font-size: 0.875rem;
        font-weight: 600;
        text-align: center;
        padding-left: 13px;
        margin: 0;
        color: #fff;
      }
    }

    .flow-page-delete-flow-button:hover {
      background-color: #d83931;
    }
  }

  .flow-page-topics-heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    width: 100%;
    max-width: 1140px;
    margin: 0;
  }

  .no-topics-text {
    margin: 24px 0 0 0;
  }

  .create-topic-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0 50px;

    .create-topic-button,
    .cancel-topic-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      color: #364a4e;
      border: 1px solid #000;
      height: 74px;
      width: 49%;
      background-color: transparent;
      cursor: pointer;
    }

    .create-topic-button:hover {
      background-color: #364a4e;
      color: #fff;
      border: none;
    }

    .cancel-topic-button:hover {
      background-color: #d74941;
      color: #fff;
      border: none;
    }
  }

  .add-topic-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #364a4e;
    border: 1px solid #000;
    height: 74px;
    width: 100%;
    max-width: 1140px;
    margin: 20px 0 50px;
    background-color: transparent;
    cursor: pointer;

    .add-topic-icon {
      font-size: 1.4rem;
      margin-right: 10px;
    }
  }

  .add-topic-button:hover {
    background-color: #364a4e;
    color: #fff;
    border: none;
  }
}

.flow-page-container-non-owner {
  margin-top: 124px;
}

@media screen and (max-width: 1300px) {
  .flow-page-container {
    padding: 0 20px;
  }
}
