.quiz-window-modal {
  .start-quiz-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 25px;

    .quiz-header {
      display: grid;
      grid-template-columns: 3fr 8fr;
      grid-template-rows: 1fr;
      column-gap: 20px;
      width: 100%;
      height: 100%;

      .quiz-thumbnail {
        grid-column: 1;
        grid-row: 1;
        width: 215px;
        height: 140px;
      }

      .quiz-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .quiz-title {
          grid-column: 2;
          grid-row: 1;
          font-size: 1.375rem;
          font-weight: 700;
          color: #000;
          margin: 0 0 5px 0;
          width: 100%;
        }

        .quiz-tags-row {
          grid-column: 2;
          grid-row: 2;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          width: 100%;
        }
      }

      .quiz-stats {
        grid-column: 3;
        grid-row: 1;
        align-items: flex-start;
        width: 100%;

        .time-stats-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 10px;

          .card-stats-icon {
            font-size: 1rem;
            color: #364a4ed5;
          }

          .like-button {
            cursor: pointer;
          }

          .card-stats {
            font-size: 1rem;
            font-weight: 400;
            color: #364a4e;
            margin: 0;
            padding-left: 6px;
            white-space: nowrap;
          }
        }
      }
    }

    .quiz-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;
      width: 100%;
      margin: 25px 0;

      .quiz-description {
        grid-column: 1;
        grid-row: 1;
        font-size: 0.875rem;
        font-weight: 400;
        color: rgba(54, 74, 78, 0.9);
        margin: 0;
      }
    }

    .quiz-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: auto;

      .cancel-quiz-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        color: #747474;
        height: 48px;
        padding: 0 48px;
        border: none;
        outline: none;
        border-radius: 50px;
        background: rgba(27, 27, 27, 0.1);
        white-space: nowrap;
        width: 188px;
        cursor: pointer;
      }

      .cancel-quiz-button:hover {
        background: rgba(27, 27, 27, 0.15);
      }

      .start-quiz-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        height: 48px;
        padding: 0 48px;
        border: none;
        outline: none;
        border-radius: 50px;
        background: #30beee;
        white-space: nowrap;
        width: 188px;
        cursor: pointer;
        margin-left: 16px;
      }

      .start-quiz-button:hover {
        background: #11b7ee;
      }
    }
  }
}
