.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-top: 84px;
  background: #e1e2e3;
  padding: 120px 20px;

  .register-form-avatar-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;

    .register-form-avatar-image {
      width: 48px;
      height: 48px;

      .avatar-image {
        width: 48px;
        height: 100%;
        border-radius: 50%;
      }

      .avatar-image-overlay {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.8);

        .delete-avatar-icon {
          color: #fff;
        }
      }

      .avatar-image:hover {
        cursor: pointer;

        .avatar-image-overlay {
          display: flex;
        }
      }

      .avatar-image-none {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 100%;
        border-radius: 50%;
        background: #f5f5f5;
      }
    }

    .register-form-avatar-input-text {
      width: 100%;
      margin-left: 16px;

      .register-form-set-avatar {
        font-size: 1.125rem;
        font-weight: 500;
        color: #4f4f4f;
        margin: 0;
      }

      .register-form-avatar-input {
        font-size: 0.75rem;
        font-weight: 500;
        color: #2d9cdb;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .register-form-name-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    width: 100%;
  }

  .MuiFilledInput-root {
    border-radius: 10px;
    height: 100%;

    .MuiFilledInput-input {
      padding: 27px 16px 8px;
      height: 29px;
      border-radius: 10px;
    }
  }

  .confirm-password-helper-text {
    margin-top: 32px !important;
  }
}

.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiPickersModal-dialog {
        .MuiPickersBasePicker-container {
          .MuiPickersToolbar-toolbar {
            background-color: #364a4e;
          }

          .MuiPickersBasePicker-pickerView {
            .MuiPickersCalendar-transitionContainer {
              .MuiPickersCalendar-week {
                .MuiPickersDay-daySelected {
                  background-color: #364a4e;
                }
              }
            }

            .MuiPickersYearSelection-container {
              .MuiTypography-colorPrimary {
                color: #364a4e;
              }
            }
          }
        }
      }

      .MuiDialogActions-spacing {
        .MuiButton-textPrimary {
          color: #364a4e;
        }
      }
    }
  }
}

.MuiMenu-paper {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  max-height: calc(43% - 96px) !important;

  .MuiList-root {
    padding: 0;

    .MuiMenuItem-root {
      padding: 22px 16px;
      font-size: 1rem;
      font-weight: 400;
      color: #8e8e8e;
      border-bottom: 1px solid #dadada;
    }

    .MuiMenuItem-root:hover {
      background-color: #364a4e;
      color: #fff;
      border-bottom: 1px solid #fff;
    }

    .Mui-selected {
      background-color: #364a4e;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
