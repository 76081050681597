.my-video-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .my-video-checkbox {
    .MuiCheckbox-root {
      position: absolute;
      top: -19px;
      right: -19px;
      padding: 0;
      z-index: 1;

      .my-video-check-icon {
        font-size: 2.2rem;
        border-radius: 50px;
        color: #219653;
        background-color: #fff;
      }
    }
  }

  .search-checkbox {
    .MuiCheckbox-root {
      position: absolute;
      top: -12px;
      right: -12px;
      padding: 0;
      z-index: 1;

      .search-check-icon {
        font-size: 1.7rem;
        border-radius: 50px;
        color: #219653;
        background-color: #fff;
      }
    }
  }

  .my-video-card-thumbnail {
    width: 100%;
    height: 160px;
    border-radius: 3px;
    position: relative;

    .content-visibility {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 6px 9px;
      background-color: #30beee;
      border-radius: 4px;
      margin: 10px;

      .content-visibility-icon {
        color: #fff;
        font-size: 0.9rem;
      }

      .content-visibility-text {
        font-size: 0.75rem;
        font-weight: 400;
        color: #fff;
        margin: 0;
        padding-left: 6px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .edit-content-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .trash-icon {
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }

      .edit-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .edit-icon {
          font-size: 1.4rem;
          font-weight: 500;
          color: #fff;
        }

        .edit-content-text {
          font-size: 1.25rem;
          font-weight: 700;
          color: #fff;
          margin: 0;
          padding-left: 11px;
        }
      }
    }
  }

  .my-video-card-thumbnail:hover {
    .edit-content-overlay {
      display: flex;
    }
  }

  .search-card-thumbnail {
    width: 100%;
    height: 130px;
    cursor: pointer;

    .content-visibility {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 5px 8px;
      background-color: #30beee;
      border-radius: 4px;
      margin: 10px;

      .content-visibility-icon {
        color: #fff;
        font-size: 0.9rem;
      }

      .content-visibility-text {
        font-size: 0.75rem;
        font-weight: 400;
        color: #fff;
        margin: 0;
        padding-left: 6px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .my-video-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 11.5px;
    width: 100%;

    .my-video-card-title {
      font-size: 1rem;
      font-weight: 700;
      color: #000;
      width: 100%;
      margin: 0;
    }

    .my-video-card-video-stats {
      width: 100%;
      margin-top: 15px;

      .video-owner-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .card-stats-icon {
          font-size: 1rem;
          color: #364a4ed5;
        }

        .owner-name {
          font-size: 1rem;
          font-weight: 400;
          color: #364a4e;
          margin: 0;
          padding-left: 6px;
          white-space: nowrap;
        }
      }

      .suggestion-buttons-wrapper {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 12px;
        width: 100%;
        margin-top: 15px;

        .reject-button,
        .approve-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
          border-radius: 8px;
          border: none;
          outline: none;
          font-size: 0.875rem;
          font-weight: 600;
          color: #fff;
        }

        .reject-button {
          background: #c5322a;
        }

        .reject-button:hover {
          background-color: #be1f16;
        }

        .approve-button {
          background: #364a4e;
        }

        .approve-button:hover {
          background: #283f44;
        }
      }
    }
  }
}
