.home-flow-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .home-flow-list-heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 37px 0;

    .home-flow-list-heading {
      font-size: 1.25rem;
      font-style: bold;
      font-weight: 700;
      margin: 0;
    }

    .home-flow-list-view-all {
      font-size: 1rem;
      font-weight: 400;
      color: #4267b2;
      border-bottom: 1px solid #4267b2;
      text-decoration: none;
    }
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .home-flow-list-grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 40px;
    margin-bottom: 60px;
    width: 100%;
  }

  .no-flows {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .home-flow-list-container {
    .home-flow-list-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 800px) {
  .home-flow-list-container {
    .home-flow-list-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 500px) {
  .home-flow-list-container {
    .home-flow-list-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
