.card-stats-row {
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-auto-flow: column;
  column-gap: 20px;
  margin: 0;

  .card-stats-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .card-stats-icon {
      font-size: 1rem;
      color: #364a4ed5;
    }

    .like-button {
      cursor: pointer;
    }

    .card-stats {
      font-size: 1rem;
      font-weight: 400;
      color: #364a4e;
      margin: 0;
      padding-left: 6px;
      white-space: nowrap;
    }
  }
}

.card-stats-row-no-margin {
  margin: 0;
}

@media screen and (max-width: 580px) {
  .card-stats-row {
    width: 100%;
  }
}
