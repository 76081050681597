.home-flow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .home-flow-title {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    margin: 0;
    align-self: flex-start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .home-flow-thumbnail {
    width: 100%;
    height: 180px;
    border-radius: 3px;
    margin: 8px 0 0;

    .home-flow-thumbnail-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .play-icon {
        font-size: 1.4rem;
        color: #fff;
      }

      .home-flow-play-flow {
        font-size: 1.25rem;
        font-weight: 700;
        color: #fff;
        margin: 0;
        padding-left: 11px;
      }
    }
  }

  .home-flow-thumbnail:hover {
    .home-flow-thumbnail-overlay {
      display: flex;
      cursor: pointer;
    }
  }

  .home-flow-stats-row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8px 0 0;

    .home-flow-stats-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .home-flow-stats-icon {
        font-size: 1rem;
        color: #364a4ed5;
      }

      .home-flow-stats {
        font-size: 0.75rem;
        font-weight: 400;
        color: #364a4e;
        margin: 0;
        padding-left: 6px;
      }
    }

    .home-flow-share-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      cursor: pointer;

      .home-flow-stats-icon {
        font-size: 1rem;
        color: #364a4ed5;
      }

      .home-flow-stats {
        font-size: 0.75rem;
        font-weight: 400;
        color: #364a4e;
        margin: 0;
        padding-left: 6px;
      }
    }
  }

  .home-flow-tags-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 2px 0 0;
  }

  .home-flow-description {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 18px;
    color: rgba(27, 27, 27, 0.9);
    margin: 8px 0 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .home-flow-owner-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 8px;

    .home-flow-user-icon {
      font-size: 1rem;
      color: #364a4ed5;
    }

    .home-flow-owner-name {
      font-size: 0.875rem;
      font-weight: 400;
      color: #364a4e;
      margin: 0 0 0 9px;
    }
  }
}

@media screen and (min-width: 1700px) {
  .home-flow-wrapper {
    .home-flow-thumbnail {
      height: 230px;
    }
  }
}
