.topic-content-list-wrapper {
  width: 100%;
  align-self: stretch;
  overflow: hidden;

  .topic-content-list {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    .chevron {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: #30beee;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .chevron-icon {
        color: #fff;
      }
    }

    .sc-bdVaJa {
      height: 100%;

      .sc-bwzfXH {
        height: 100%;
        overflow: hidden;

        .sc-htpNat {
          height: 100%;

          .sc-bxivhb {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
