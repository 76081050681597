.modal-dialog {
  max-width: 852px;

  .modal-content {
    border: none;
    border-radius: 0;

    .tag-modal {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 315px;
      padding: 48px;

      .tag-modal-title {
        font-size: 2.25rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 18px;
      }

      .tag-modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: flex-start;
        width: 100%;

        .tag-modal-tags-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .tag-modal-no-tags {
            margin: 0 8px 0 0;
          }
        }

        .tag-modal-tags-form {
          .tag-modal-tags-form-heading {
            font-size: 1.125rem;
            font-weight: 500;
            color: #4f4f4f;
            margin: 18px 0 6px 0;
          }

          .tag-modal-tags-form-input-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #c9c9c9;
            border-radius: 3px;
            width: 60%;

            .tag-modal-tags-form-input {
              font-size: 1rem;
              height: 48px;
              padding: 0 10px;
              border-radius: 3px;
              border: none;
              outline: none;
              width: 100%;
            }

            .tag-modal-add-button {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              font-size: 0.875rem;
              font-weight: 400;
              color: #fff;
              border: none;
              outline: none;
              white-space: nowrap;
              padding: 5px;
              margin: 10px;
            }
          }

          .tag-limit-warning {
            margin-top: 10px;
            font-size: 0.8rem;
            color: #d61a3c;
            display: block;
          }
        }
      }

      .tag-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .tag-modal-finish-button {
          display: flex;
          justify-content: center;
          align-content: center;
          padding: 0 34px;
          height: 48px;
          border-radius: 42px;
          cursor: pointer;

          .tag-modal-finish-button-text {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 700;
            color: #fff;
            margin: 0;
          }
        }

        .tag-modal-cancel-button-text {
          font-size: 1.25rem;
          font-weight: 700;
          color: rgba(27, 27, 27, 0.6);
          margin: 0 12px 0 44px;
          cursor: pointer;
        }
      }

      .flow-button {
        background-color: #f9a159;
      }

      .flow-button:hover {
        background-color: #fa9746;
      }

      .topic-button {
        background-color: #30beee;
      }

      .topic-button:hover {
        background-color: #11b7ee;
      }
    }

    .flow-modal {
      border-top: 5px solid #f9a159;
    }

    .topic-modal {
      border-top: 5px solid #30beee;
    }
  }
}
