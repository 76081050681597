.navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .navbar-search-bar {
    position: relative;
    border-radius: 4px;

    .MuiPaper-elevation1 {
      background: rgba(249, 249, 249, 0.9);
      border-radius: 4px;
      box-shadow: none;
      width: 518px;

      .MuiInput-root {
        .MuiInput-input {
          font-size: 1.15rem;
          font-weight: 400;
          color: #1b1b1b, 60%;
          padding: 6px 0;
        }
      }

      .MuiIconButton-root {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            fill: unset;
          }
        }
      }
    }
  }

  .nav-menu-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .new-flow-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      height: 48px;
      white-space: nowrap;
      margin-right: 16px;
      border-radius: 47px;
      background-color: #f9a159;
      cursor: pointer;

      .new-flow-button-icon {
        color: #fff;
        font-size: 1.2rem;
      }

      h3 {
        color: #fff;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: center;
        margin: 0 0 0 14px;
      }
    }

    .new-flow-button-wrapper:hover {
      background-color: #fa9746;
    }

    .navbar-profile-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      padding: 0 10.5px;
      border-radius: 47px;
      background-color: #fafafa;
      cursor: pointer;

      p {
        color: #747474;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        margin: 0 10px 0 0;
      }

      .navbar-profile-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 31px;
        height: 31px;
        overflow: hidden;
        border-radius: 50%;

        .navbar-profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .auth-buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .navbar-menu {
    .navbar-search-bar {
      .MuiPaper-elevation1 {
        width: 418px;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .navbar-menu {
    display: none;
  }
}
