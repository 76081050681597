.add-video-modal {
  .modal-dialog {
    max-width: 1000px;

    .modal-content {
      position: unset;
      border: none;
      border-radius: 0;

      .edit-video-player {
        position: relative;

        .close-modal-icon {
          position: absolute;
          font-size: 2.2rem;
          color: #d74941;
          background-color: #fff;
          border-radius: 50%;
          top: -15px;
          right: -15px;
          z-index: 1;
          cursor: pointer;
        }
      }

      .add-topic-video-modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 905px;
        padding: 30px 0px 30px 30px;

        .add-topic-video-modal-header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 22px;
          padding-right: 30px;

          .add-topic-video-modal-heading {
            font-size: 1.25rem;
            font-weight: 700;
            color: #000;
            margin-bottom: 32px;
          }

          .add-topic-video-modal-tab-labels-wrapper {
            display: grid;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;
            column-gap: 24px;
            cursor: pointer;

            .add-topic-video-modal-tab-label {
              font-size: 1rem;
              font-weight: 400;
              color: #000;
              padding-bottom: 3px;
              border-bottom: 2px solid transparent;
            }

            .add-topic-video-modal-tab-selected {
              border-bottom: 2px solid #f9a159;
            }
          }

          .MuiPaper-elevation1 {
            box-shadow: none;
            border: 1px solid #000;
            margin-top: 39px;
            width: 100%;

            .MuiInput-root {
              .MuiInput-input {
                font-size: 1rem;
                font-weight: 400;
                padding: 6px 0;
              }
            }

            .MuiIconButton-root {
              .MuiIconButton-label {
                .MuiSvgIcon-root {
                  fill: unset;
                }
              }
            }
          }
        }

        .modal-body {
          padding: 0;

          .add-topic-video-modal-my-videos-panel {
            width: 100%;
            padding-right: 30px;

            .my-videos-panel-grid {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              column-gap: 50px;
              row-gap: 21px;
            }

            .no-videos {
              width: 100%;
              text-align: center;
              font-size: 1rem;
            }
          }

          .add-topic-video-modal-upload-panel {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            padding-right: 30px;

            .upload-panel-form {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              width: 100%;

              .upload-panel-video-input-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;

                .upload-panel-video-input-button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 41px;
                  padding: 0 42.5px;
                  border-radius: 27px;
                  background-color: #30beee;
                  font-size: 1rem;
                  font-weight: 700;
                  color: #fff;
                  cursor: pointer;
                }

                .upload-panel-file-video-button:hover {
                  background-color: #11b7ee;
                }

                .upload-panel-video-input-text {
                  font-size: 1rem;
                  font-weight: 700;
                  color: rgba(27, 27, 27, 0.9);
                  margin: 0 0 0 16px;

                  .upload-check-icon {
                    color: #219653;
                    margin-left: 10.5px;
                  }
                }
              }

              .upload-panel-thumbnail-input-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 6px;

                .upload-panel-thumbnail {
                  width: 300px;
                  height: 160px;

                  .upload-panel-thumbnail-overlay {
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    background: rgba(0, 0, 0, 0.6);

                    .cross-icon {
                      font-size: 2rem;
                      color: #fff;
                    }
                  }
                }

                .upload-panel-thumbnail:hover {
                  .upload-panel-thumbnail-overlay {
                    display: flex;
                    cursor: pointer;
                  }
                }

                .upload-panel-thumbnail-buttons {
                  display: grid;
                  justify-content: center;
                  align-items: center;
                  grid-auto-flow: column;
                  column-gap: 15px;

                  .upload-panel-thumbnail-edit,
                  .upload-panel-thumbnail-play {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #c5322a;
                    margin: 12px 0 0 0;
                    border-bottom: 1px solid #c5322a;
                    cursor: pointer;
                  }
                }
              }

              .upload-panel-video-details-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 36px;

                .MuiFormLabel-root.Mui-focused {
                  color: rgba(27, 27, 27, 0.6);
                }

                .MuiInput-underline:before {
                  border-bottom: 1px solid rgba(27, 27, 27, 0.1);
                }

                .MuiInput-underline:after {
                  border-bottom: 1px solid rgba(27, 27, 27, 0.1);
                }

                .upload-panel-video-input {
                  width: 100%;
                  font-size: 1rem;
                  font-weight: 400;
                  color: rgba(27, 27, 27, 0.9);
                  margin-top: 18px;
                }

                .upload-panel-tags-row {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  width: 100%;
                  margin-top: 8px;
                }

                .tag-limit-warning {
                  margin-top: 6px;
                  font-size: 0.8rem;
                  color: #d61a3c;
                  display: block;
                }

                .upload-panel-tags-input-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-end;
                  width: 100%;

                  .upload-panel-add-tag-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid rgba(27, 27, 27, 0.1);

                    .upload-panel-add-tag-button {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 3px;
                      font-size: 0.875rem;
                      font-weight: 400;
                      color: #fff;
                      background: #30beee;
                      border: none;
                      outline: none;
                      white-space: nowrap;
                      padding: 5px;
                      margin: 6px 0 6px 6px;
                    }

                    .upload-panel-add-tag-button:hover {
                      background-color: #11b7ee;
                    }
                  }
                }
              }

              .upload-panel-video-notes-grid {
                display: grid;
                justify-content: flex-start;
                align-items: center;
                grid-auto-flow: row;
                row-gap: 12px;
                width: 100%;
                margin-bottom: 36px;

                .video-notes-heading {
                  font-size: 0.875rem;
                  font-weight: 700;
                  color: rgba(27, 27, 27, 0.9);
                  margin: 0;
                }

                .upload-notes-input {
                  font-size: 1rem;
                  font-weight: 400;
                  color: #c5322a;
                  width: fit-content;
                  margin: 5px 0 0 0;
                  border-bottom: 1px solid #c5322a;
                  cursor: pointer;
                }
              }

              .upload-panel-video-visibility-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 47px;

                .video-visibility-heading {
                  font-size: 0.875rem;
                  font-weight: 700;
                  color: rgba(27, 27, 27, 0.9);
                  margin-bottom: 12px;
                }

                .MuiFormGroup-root {
                  flex-direction: row;
                  height: auto;

                  .MuiFormControlLabel-root {
                    margin: 0;

                    .MuiIconButton-root {
                      padding: 0;
                    }

                    .MuiFormControlLabel-label {
                      font-size: 0.875rem;
                      font-weight: 400;
                      color: rgba(27, 27, 27, 0.6);
                      margin: 0 13.5px 0 5px;
                    }
                  }
                }
              }
            }
          }

          .add-topic-video-modal-search-panel {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: space-between;
            width: 100%;
            padding-right: 30px;

            .search-panel-grid-heading {
              font-size: 1.25rem;
              font-weight: 400;
              color: #767676;
              margin: 0 0 5px;
            }

            .search-panel-grid {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              column-gap: 20px;
              row-gap: 21px;
            }
          }
        }

        .modal-footer {
          justify-content: flex-start;
          border: none;
          padding: 50px 30px 0 0;

          .video-modal-footer-buttons-wrapper {
            display: grid;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;
            column-gap: 10px;
            width: 100%;
            margin: 0;

            .my-videos-add-button {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              font-weight: 700;
              color: #fff;
              height: 41px;
              padding: 0 50px;
              margin: 0;
              border: none;
              outline: none;
              border-radius: 50px;
              background: #364a4e;
              cursor: pointer;
            }

            .my-videos-add-button:hover {
              background-color: #283f44;
            }

            .upload-video-button {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              font-weight: 700;
              color: #fff;
              height: 41px;
              padding: 0 60.5px;
              border: none;
              outline: none;
              border-radius: 50px;
              background: #364a4e;
              cursor: pointer;
            }

            .upload-video-button:hover {
              background-color: #283f44;
            }

            .search-add-button {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              font-weight: 700;
              color: #fff;
              height: 41px;
              padding: 0 50px;
              border: none;
              outline: none;
              border-radius: 50px;
              background: #364a4e;
              cursor: pointer;
            }

            .search-add-button:hover {
              background-color: #283f44;
            }

            .cancel-button {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              font-weight: 700;
              color: #747474;
              height: 41px;
              padding: 0 60.5px;
              border: none;
              outline: none;
              border-radius: 50px;
              background: rgba(27, 27, 27, 0.1);
              cursor: pointer;
            }

            .cancel-button:hover {
              background: rgba(27, 27, 27, 0.15);
            }
          }
        }
      }
    }
  }
}
