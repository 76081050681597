.bordered-filter {
  width: 200px;
  height: 41px;
  border: 1px solid rgba(27, 27, 27, 0.6) !important;
  border-radius: 3px !important;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;

    .MuiInputBase-input {
      padding: 10px 40px 10px 18px;
      border-radius: 3px;
      font-size: 1rem;
      color: rgba(27, 27, 27, 0.9);
    }

    .MuiSelect-select:focus {
      background-color: #fff;
    }
  }

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
}

.underlined-filter {
  width: 105px;
  height: 40px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.1) !important;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;

    .MuiInputBase-input {
      padding: 0 40px 0 0;
      border-radius: 3px;
      font-size: 1rem;
      color: rgba(27, 27, 27, 0.9);
    }

    .MuiSelect-select:focus {
      background-color: #fff;
    }

    .MuiSvgIcon-root {
      margin: 0;
    }
  }

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
}

.MuiMenu-paper.home-dropdown-menu {
  background: #fff;
  box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;

  .MuiList-root {
    padding: 0;

    .MuiMenuItem-root {
      padding: 10px 18px;
      font-size: 1rem;
      font-weight: 400;
      color: #262626;
      border: none;
    }

    .MuiMenuItem-root:hover {
      background-color: #f5f5f5;
      color: #262626;
      border: none;
    }

    .Mui-selected {
      background-color: #f5f5f5;
      color: #302a38;
      font-weight: 600;
    }
  }
}
