.topic-card-collapsed-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  padding: 20px;
  background-color: #ebfaff;
  border: 1px solid #b9b9b9;
  margin: 24px 0;
  position: relative;

  .topic-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .topic-card-collapsed-row-1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .topic-card-collapsed-title-stats-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;

        .topic-card-collapsed-title-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: start;
          font-size: 1.5rem;
          font-weight: 700;
          color: #000;
          resize: none;
          height: 43px;
          width: 90%;
          padding: 2px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .topic-card-collapsed-title-textarea:focus {
          background: rgba(48, 190, 238, 0.15);
          border: 1px solid #92e3ff;
        }
      }

      .topic-expand-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f9a159;
        border-radius: 47px;
        padding: 0 24px;
        height: 48px;
        cursor: pointer;

        .topic-expand-icon {
          color: #fff;
        }

        .topic-expand-button-text {
          font-size: 0.875rem;
          color: #fff;
          text-align: center;
          padding-left: 12px;
          margin: 0;
        }
      }

      .topic-expand-button:hover {
        background-color: #fa9746;
      }
    }

    .topic-card-collapsed-row-2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;

      .topic-card-collapsed-thumbnail {
        width: 400px;
        height: 244px;

        .topic-card-collapsed-play-overlay {
          display: none;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          background: rgba(0, 0, 0, 0.6);

          .play-icon {
            font-size: 2.7rem;
            color: #fff;
          }
        }

        .topic-card-collapsed-play-overlay-none {
          display: none;
        }

        .topic-card-collapsed-thumbnail-overlay {
          display: none;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          background: rgba(0, 0, 0, 0.6);

          .cross-icon {
            font-size: 2rem;
            color: #fff;
          }
        }
      }

      .topic-card-collapsed-thumbnail:hover {
        .topic-card-collapsed-thumbnail-overlay {
          display: flex;
          cursor: pointer;
        }

        .topic-card-collapsed-play-overlay {
          display: flex;
          cursor: pointer;
        }
      }

      .topic-card-collapsed-details-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        margin: 0 0 0 18px;
        flex: 1;

        .topic-card-collapsed-details-div-1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .topic-card-collapsed-description-textarea {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: start;
            font-size: 0.875rem;
            font-weight: 400;
            color: rgba(27, 27, 27, 0.9);
            resize: none;
            height: 96px;
            width: 80%;
            padding: 3px 6px 0;
            border: none;
            outline: none;
            background-color: transparent;
            border: 1px solid transparent;
            box-sizing: border-box;
          }

          .topic-card-collapsed-description-textarea:focus {
            background: rgba(48, 190, 238, 0.15);
            border: 1px solid #92e3ff;
          }
        }

        .topic-card-collapsed-details-div-2 {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;

          .MuiFormControlLabel-root {
            margin: 8px 0;

            .MuiButtonBase-root {
              padding: 0;

              .MuiIconButton-label {
                width: 16px;
                height: 16px;

                .MuiSvgIcon-root {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .MuiFormControlLabel-label {
              font-size: 0.875rem;
              font-weight: 400;
              color: rgba(27, 27, 27, 0.6);
              padding: 0 0 0 10px;
            }
          }

          .MuiCheckbox-colorPrimary.Mui-checked {
            color: #30beee;
          }
        }

        .topic-card-collapsed-details-div-3 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .topic-card-collapsed-owner-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: 6px 0;

            .user-icon {
              font-size: 1.2rem;
              color: #364a4ee1;
            }

            .topic-card-collapsed-owner-name {
              font-size: 1.125rem;
              font-weight: 400;
              color: #364a4e;
              margin: 0;
              padding-left: 11px;
            }
          }

          .topic-card-collapsed-visibility-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 10px;

            .topic-card-collapsed-visibility-heading {
              font-size: 0.875rem;
              font-weight: 700;
              color: rgba(27, 27, 27, 0.9);
              margin-bottom: 5px;
            }

            .MuiFormGroup-root {
              flex-direction: row;
              height: auto;

              .MuiFormControlLabel-root {
                margin: 0;

                .MuiIconButton-root {
                  padding: 0;
                }

                .MuiFormControlLabel-label {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: rgba(27, 27, 27, 0.6);
                  margin: 0 13.5px 0 5px;
                }
              }
            }
          }

          .topic-card-collapsed-tags-and-buttons-wrapper {
            display: grid;
            justify-content: space-between;
            align-items: flex-end;
            grid-auto-flow: column;
            column-gap: 10px;
            width: 100%;

            .topic-card-collapsed-tags-row {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;

              .topic-card-collapsed-no-tags {
                margin: 0 8px 0 0;
              }

              .topic-card-collapsed-add-tag-button {
                display: flex;
                align-items: center;
                padding: 5px 9px;
                margin: 6px 0 0 0 !important;
                border-radius: 3px;
                background: rgba(0, 0, 0, 0.53);
                cursor: pointer;

                .add-icon {
                  font-size: 0.9rem;
                  color: #fff;
                  margin-right: 8px;
                }

                .topic-card-collapsed-add-tag-button-text {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: #fff;
                  margin: 0;
                }
              }

              .topic-card-collapsed-add-tag-button:hover {
                background: rgba(0, 0, 0, 0.575);
              }
            }

            .topic-card-buttons-div {
              display: grid;
              justify-content: center;
              align-items: flex-end;
              grid-auto-flow: column;
              column-gap: 20px;

              .share-topic-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #30beee;
                border-radius: 47px;
                padding: 0 24px;
                height: 48px;
                white-space: nowrap;
                cursor: pointer;

                .share-topic-button-text {
                  font-size: 0.875rem;
                  color: #fff;
                  text-align: center;
                  padding-left: 12px;
                  margin: 0;
                }
              }

              .share-topic-button:hover {
                background-color: #11b7ee;
              }

              .suggest-video-button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #364a4e;
                border-radius: 47px;
                padding: 0 24px;
                height: 48px;
                white-space: nowrap;
                cursor: pointer;

                .suggest-video-button-text {
                  font-size: 0.875rem;
                  color: #fff;
                  text-align: center;
                  padding-left: 12px;
                  margin: 0;
                }
              }

              .suggest-video-button:hover {
                background-color: #283f44;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .topic-card-collapsed-wrapper {
    .topic-form {
      .topic-card-collapsed-row-2 {
        .topic-card-collapsed-thumbnail {
          width: 100%;
          height: 300px;
        }

        .topic-card-collapsed-details-wrapper {
          margin: 20px 18px 0 0;
        }

        .share-flow-button-div {
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .topic-card-collapsed-wrapper {
    .topic-form {
      .topic-card-collapsed-row-1 {
        .topic-card-collapsed-title-stats-wrapper {
          .topic-card-collapsed-title-textarea {
            width: 100%;
          }

          .topic-card-collapsed-stats-row {
            width: 100%;
          }
        }
      }

      .topic-card-collapsed-row-2 {
        .topic-card-collapsed-thumbnail {
          height: 200px;
        }

        .topic-card-collapsed-details-wrapper {
          margin: 20px 0 0 0;
        }
      }
    }
  }
}
