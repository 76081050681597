.take-quiz-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 25px;

  .quiz-header {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-template-rows: 1fr;
    column-gap: 20px;
    width: 100%;
    height: 100%;

    .quiz-thumbnail {
      grid-column: 1;
      grid-row: 1;
      width: 195px;
      height: 125px;
    }

    .quiz-title {
      grid-column: 2;
      grid-row: 1;
      font-size: 1.375rem;
      font-weight: 700;
      color: #000;
      margin: 0 0 5px 0;
    }

    .quiz-stats {
      grid-column: 3;
      grid-row: 1;
    }

    .quiz-description {
      grid-column: 2;
      grid-row: 1;
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(54, 74, 78, 0.9);
      margin: 0;
    }
  }

  .quiz-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid rgba(54, 74, 78, 0.25);
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin: 25px 0;

    .quiz-progress {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 100%;
      height: 8px;
      background: #f4f4f4;
      border-radius: 100px;
      margin: 30px 0;

      .question-progress-indicator {
        position: absolute;
        top: -25px;
        right: 0;
        font-size: 0.875rem;
        font-weight: 400;
        color: #364a4e;
        margin: 0;
      }

      .question-progress {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        background: #f9a159;
        border-radius: 100px;
        transition: width 0.5s;
      }
    }

    .question-section {
      display: grid;
      grid-template-columns: 3fr;
      column-gap: 20px;
      width: 100%;

      .quiz-settings-wrapper {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        .quiz-time {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 15px;
          background: #364a4e;
          border-radius: 8px;
          font-size: 1.125rem;
          font-weight: 400;
          color: #fff;
          white-space: nowrap;
          margin: 0 0 20px;
          letter-spacing: 1px;

          .text {
            font-size: 1rem;
            color: #fff;
            margin: 0 10px 0 0;
          }
        }

        .quiz-points-wrapper,
        .quiz-points-wrapper.penalty {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .point-icon {
            font-size: 1.1rem;
            color: #00b500;
            background: #fff;
          }

          .quiz-points-text {
            font-size: 1.1rem;
            font-weight: 400;
            color: #364a4e;
            margin: 0;
            padding-left: 6px;

            .quiz-points-number {
              font-size: 1.2rem;
              font-weight: 500;
              color: #000;
              letter-spacing: 1px;
              margin-left: 2px;
            }
          }
        }

        .quiz-points-wrapper.penalty {
          margin-top: 10px;

          .penalty-icon {
            font-size: 1.1rem;
            color: #ea0000;
            background: #fff;
          }

          .quiz-points-text {
            font-size: 1.1rem;
            font-weight: 400;
            color: #364a4e;
            margin: 0;
            padding-left: 6px;

            .quiz-points-number {
              font-size: 1.2rem;
              font-weight: 500;
              color: #000;
              letter-spacing: 1px;
              margin-left: 2px;
            }
          }
        }
      }

      .quiz-question-answer-wrapper {
        grid-column: 1;
        grid-row: 1 / span 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .quiz-question-number {
          font-size: 1.25rem;
          font-weight: 700;
          color: #000;
          margin: 0 0 15px 0;
        }

        .quiz-question {
          font-size: 1rem;
          font-weight: 400;
          color: #000;
          margin: 0 0 15px 0;
        }

        .MuiFormGroup-root {
          .MuiFormControlLabel-root {
            align-items: flex-start;
            margin: 0 0 10px 0;

            .MuiButtonBase-root {
              padding-top: 4px;
              padding-left: 0;
            }

            .MuiTypography-root {
              font-size: 0.9rem;
              font-weight: 400;
              color: #000;
            }
          }
        }
      }

      .no-questions {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 1 / span 2;
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }

  .quiz-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: auto;

    .leave-quiz-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      color: #747474;
      height: 48px;
      padding: 0 48px;
      border: none;
      outline: none;
      border-radius: 50px;
      background: rgba(27, 27, 27, 0.1);
      white-space: nowrap;
      width: 188px;
    }

    .leave-quiz-button:hover {
      background: rgba(27, 27, 27, 0.15);
    }

    .previous-and-next-buttons {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 16px;

      .previous-question-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        height: 48px;
        padding: 0 48px;
        border: none;
        outline: none;
        border-radius: 50px;
        background: #364a4e;
        white-space: nowrap;
        width: 188px;
      }

      .previous-question-button:hover {
        background: #283f44;
      }

      .next-question-button,
      .submit-quiz-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        height: 48px;
        padding: 0 48px;
        border: none;
        outline: none;
        border-radius: 50px;
        background: #30beee;
        white-space: nowrap;
        width: 188px;
      }

      .next-question-button:hover {
        background: #11b7ee;
      }

      .submit-quiz-button {
        background: #f9a159;
      }

      .submit-quiz-button:hover {
        background: #fa9746;
      }
    }
  }
}
