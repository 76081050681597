.topic-featured-content-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 234px;
  margin-top: 15px;

  .topic-featured-content-card-thumbnail {
    position: relative;
    width: 234px;
    height: 150px;

    .topic-content-card-thumbnail-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .play-icon {
        font-size: 1.5rem;
        color: #fff;
      }

      .take-quiz-icon {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
      }

      .topic-content-card-take-quiz-text {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        margin: 0;
        padding-left: 6px;
      }
    }

    .featured-content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 6px;
      background-color: #ee4d4d;
      border-radius: 4px;
      margin: 10px;

      .featured-content-icon {
        color: #fff;
        font-size: 0.8rem;
      }

      .featured-content-text {
        font-size: 0.75rem;
        font-weight: 400;
        color: #fff;
        margin: 0;
        padding-left: 6px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .remove-featured-content:hover {
      cursor: pointer;
      background-color: #f04040;
    }
  }

  .topic-featured-content-card-thumbnail:hover {
    .topic-content-card-thumbnail-overlay {
      display: flex;
      cursor: pointer;
    }
  }

  .topic-featured-content-card-title-and-stats-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 12px;
    width: 100%;
    flex: 1;

    .topic-featured-content-card-title-row {
      display: grid;
      justify-content: space-between;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 15px;
      width: 100%;

      .topic-featured-content-card-title {
        font-size: 1.375rem;
        font-weight: 700;
        color: #000;
        width: 100%;
        text-align: start;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .topic-content-card-video-stats {
      margin-top: 15px;

      .video-owner-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .card-stats-icon {
          font-size: 1rem;
          color: #364a4ed5;
        }

        .owner-name {
          font-size: 1rem;
          font-weight: 400;
          color: #364a4e;
          margin: 0;
          padding-left: 6px;
          white-space: nowrap;
        }
      }
    }
  }
}

.topic-featured-content-card-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 234px;
  background: #c4c4c4;
  margin-top: 15px;

  .no-featured-content-text {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin: 0;
    width: 234px;
  }
}
