.create-flow-card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1140px;
  padding: 20px;
  background-color: #fff7f0;
  border: 1px solid #fe8113;

  .create-flow-form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .create-flow-card-thumbnail {
      width: 400px;
      height: 244px;
      align-self: stretch;
      cursor: pointer;

      .create-flow-card-thumbnail-overlay {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.6);

        .cross-icon {
          font-size: 2rem;
          color: #fff;
        }
      }
    }

    .create-flow-card-thumbnail:hover {
      .create-flow-card-thumbnail-overlay {
        display: flex;
        cursor: pointer;
      }
    }

    .create-flow-card-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      margin: 0 18px;
      flex-grow: 1;

      .create-flow-card-details-div-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .create-flow-card-title-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: start;
          font-size: 1.5rem;
          font-weight: 700;
          color: #000;
          resize: none;
          height: 43px;
          width: 90%;
          padding: 2px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
        }

        .create-flow-card-title-textarea:focus {
          background-color: rgba(249, 161, 89, 0.15);
          border: 1px solid #f9a159;
        }

        .create-flow-card-description-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: start;
          font-size: 0.875rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.9);
          resize: none;
          height: 72px;
          width: 90%;
          padding: 3px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
        }

        .create-flow-card-description-textarea:focus {
          background-color: rgba(249, 161, 89, 0.15);
          border: 1px solid #f9a159;
        }
      }

      .create-flow-card-details-div-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .create-flow-card-owner-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 6px 0 14px;

          .user-icon {
            font-size: 1.2rem;
            color: #364a4ee1;
          }

          .create-flow-card-owner-name {
            font-size: 1.125rem;
            font-weight: 400;
            color: #364a4e;
            margin: 0;
            padding-left: 11px;
          }
        }

        .create-flow-card-visibility-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;

          .create-flow-card-visibility-heading {
            font-size: 0.875rem;
            font-weight: 700;
            color: rgba(27, 27, 27, 0.9);
            margin-bottom: 5px;
          }

          .MuiFormGroup-root {
            flex-direction: row;
            height: auto;

            .MuiFormControlLabel-root {
              margin: 0;

              .MuiIconButton-root {
                padding: 0;
              }

              .MuiFormControlLabel-label {
                font-size: 0.875rem;
                font-weight: 400;
                color: rgba(27, 27, 27, 0.6);
                margin: 0 13.5px 0 5px;
              }
            }
          }
        }

        .create-flow-card-tags-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          .create-flow-card-add-tag-button {
            display: flex;
            align-items: center;
            padding: 5px 9px;
            margin: 6px 0 0 0 !important;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.53);
            cursor: pointer;

            .add-icon {
              font-size: 0.9rem;
              color: #fff;
              margin-right: 8px;
            }

            .create-flow-card-add-tag-button-text {
              font-size: 0.875rem;
              font-weight: 400;
              color: #fff;
              margin: 0;
            }
          }

          .create-flow-card-add-tag-button:hover {
            background: rgba(0, 0, 0, 0.575);
          }
        }
      }
    }

    .create-flow-buttons-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 20px 0 50px;

      .create-flow-button,
      .cancel-flow-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 700;
        color: #364a4e;
        border: 1px solid #000;
        height: 74px;
        width: 49%;
        cursor: pointer;
      }

      .create-flow-button:hover {
        background-color: #364a4e;
        color: #fff;
        border: none;
      }

      .cancel-flow-button:hover {
        background-color: #d74941;
        color: #fff;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .create-flow-card-wrapper {
    .create-flow-form {
      .create-flow-card-thumbnail {
        width: 100%;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .create-flow-card-wrapper {
    .create-flow-form {
      flex-direction: column;

      .create-flow-card-details-wrapper {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .create-flow-card-wrapper {
    .create-flow-form {
      .create-flow-card-thumbnail {
        height: 200px;
      }

      .create-flow-card-details-wrapper {
        .create-flow-card-details-div-1 {
          .create-flow-card-title-textarea {
            width: 100%;
          }

          .create-flow-card-description-textarea {
            width: 100%;
          }
        }
      }
    }
  }
}
