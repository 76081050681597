.tag-wrapper {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 6px 6px 0 0 !important;
  border-radius: 3px;

  .tag-text {
    font-size: 0.875rem;
    font-weight: 400;
    color: #fff;
    margin: 0;
  }

  .tag-delete-icon {
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
  }
}

.tag-wrapper-flow {
  background-color: #f9a159;
}

.tag-wrapper-topic {
  background-color: #30beee;
}
