.flow-card-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1140px;
  padding: 20px;
  background-color: #fff7f0;
  border: 1px solid #fe8113;
  margin-bottom: 48px;

  .flow-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .flow-card-thumbnail {
      width: 400px;
      height: 244px;
      align-self: stretch;

      .flow-card-thumbnail-overlay {
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.6);

        .cross-icon {
          font-size: 2rem;
          color: #fff;
        }
      }
    }

    .flow-card-thumbnail:hover {
      .flow-card-thumbnail-overlay {
        display: flex;
        cursor: pointer;
      }
    }

    .flow-card-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      margin: 0 0 0 18px;
      flex-grow: 1;

      .flow-card-details-div-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .flow-card-title-and-share-button-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .flow-card-title-textarea-and-stats-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
            margin-right: 18px;

            .flow-card-title-textarea {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              text-align: start;
              font-size: 1.5rem;
              font-weight: 700;
              color: #000;
              resize: none;
              height: 43px;
              width: 100%;
              padding: 2px 6px 0;
              border: none;
              outline: none;
              background-color: transparent;
              border: 1px solid transparent;
              box-sizing: border-box;
            }

            .flow-card-title-textarea:focus {
              background-color: rgba(249, 161, 89, 0.15);
              border: 1px solid #f9a159;
            }
          }

          .share-flow-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f9a159;
            border-radius: 47px;
            padding: 0 24px;
            height: 48px;
            white-space: nowrap;
            cursor: pointer;

            .flow-card-share-flow {
              font-size: 0.875rem;
              color: #fff;
              text-align: center;
              padding-left: 15px;
              margin: 0;
            }
          }

          .share-flow-button:hover {
            background-color: #fa9746;
          }
        }

        .flow-card-description-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: start;
          font-size: 0.875rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.9);
          resize: none;
          height: 72px;
          width: 90%;
          padding: 3px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
        }

        .flow-card-description-textarea:focus {
          background-color: rgba(249, 161, 89, 0.15);
          border: 1px solid #f9a159;
        }
      }

      .flow-card-details-div-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .flow-card-owner-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 6px 0 14px;

          .user-icon {
            font-size: 1.2rem;
            color: #364a4ee1;
          }

          .flow-card-owner-name {
            font-size: 1.125rem;
            font-weight: 400;
            color: #364a4e;
            margin: 0;
            padding-left: 11px;
          }
        }

        .flow-card-visibility-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;

          .flow-card-visibility-heading {
            font-size: 0.875rem;
            font-weight: 700;
            color: rgba(27, 27, 27, 0.9);
            margin-bottom: 5px;
          }

          .MuiFormGroup-root {
            flex-direction: row;
            height: auto;

            .MuiFormControlLabel-root {
              margin: 0;

              .MuiIconButton-root {
                padding: 0;
              }

              .MuiFormControlLabel-label {
                font-size: 0.875rem;
                font-weight: 400;
                color: rgba(27, 27, 27, 0.6);
                margin: 0 13.5px 0 5px;
              }
            }
          }
        }

        .flow-card-tags-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          .flow-card-no-tags {
            margin: 0 8px 0 0;
          }

          .flow-card-add-tag-button {
            display: flex;
            align-items: center;
            padding: 5px 9px;
            margin: 6px 0 0 0 !important;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.53);
            cursor: pointer;

            .add-icon {
              font-size: 0.9rem;
              color: #fff;
              margin-right: 8px;
            }

            .flow-card-add-tag-button-text {
              font-size: 0.875rem;
              font-weight: 400;
              color: #fff;
              margin: 0;
            }
          }

          .flow-card-add-tag-button:hover {
            background: rgba(0, 0, 0, 0.575);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .flow-card-wrapper {
    .flow-card-thumbnail {
      width: 100%;
      height: 300px;
    }

    .flow-card-details-wrapper {
      margin: 20px 18px 0 0;
    }

    .share-flow-button-div {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 580px) {
  .flow-card-wrapper {
    .flow-card-thumbnail {
      height: 200px;
    }

    .flow-card-details-wrapper {
      margin: 20px 0 0 0;

      .flow-card-details-div-1 {
        .flow-card-title-textarea {
          width: 100%;
        }

        .flow-card-description-textarea {
          width: 100%;
        }
      }
    }
  }
}
