.quiz-question {
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .quiz-question-form {
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .quiz-category-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      border: 1px solid rgba(42, 78, 133, 0.13);
      border-radius: 8px;
      padding: 4px;
      height: 40px;
      margin: 24px 0 6px;

      .category-button {
        background: #fff;
        border-radius: 5px;
        border: none;
        outline: none;
        height: 100%;
        flex: 1;
        color: #858585;
      }

      .selected {
        background: #f9a159;
        color: #fff;
        box-shadow: 0px 11px 10px rgba(249, 161, 89, 0.1);
      }
    }

    .quiz-answer-heading {
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(27, 27, 27, 0.6);
      margin: 24px 0 0 0;
    }

    .error-message {
      margin-top: 10px;
    }

    .add-answer {
      font-size: 0.75rem;
      font-weight: 500;
      color: #f9a159;
      cursor: pointer;
      margin-top: 10px;
    }

    .add-question-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      color: #364a4e;
      height: 41px;
      padding: 0 35px 2px;
      border: none;
      outline: none;
      border: 1px solid #364a4e;
      border-radius: 40px;
      cursor: pointer;
      background: #fff;
      margin-top: 30px;
    }

    .add-question-button:hover {
      background: #364a4e;
      color: #fff;
    }

    .update-question-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      color: #fff;
      height: 41px;
      padding: 0 35px 2px;
      border: none;
      outline: none;
      border-radius: 40px;
      cursor: pointer;
      background: #f9a159;
      margin-top: 30px;
    }

    .update-question-button:hover {
      background: #fa9746;
    }
  }
}
