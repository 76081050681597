.home-page-container {
  margin-top: 84px;

  .home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .home-page-container {
    padding: 0 40px;
  }
}

@media screen and (max-width: 960px) {
  .home-page-container {
    padding: 0 20px;
  }
}
