.my-certificate-wrapper {
  position: relative;
  padding-top: 60%;
  width: 100%;

  .flow-associated {
    position: absolute;
    font-weight: 700;
    color: #333333;
    text-align: start;
    margin: 0;
  }

  .my-certificate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .certificate-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .certificate-badge-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
      }
    }

    .certificate-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .title {
        font-weight: 700;
        color: #333333;
        margin: 0;
        text-align: center;
      }

      .score {
        font-weight: 700;
        color: #333333;
        text-align: center;
        line-height: 140%;
      }

      .detail {
        font-weight: 400;
        color: rgba(51, 51, 51, 0.7);
        margin: 0;
        text-align: center;

        .name {
          font-weight: 600;
          color: #333333;
        }
      }
    }

    .certificate-footer {
      display: grid;
      justify-content: space-between;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;

      .issue-date-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .certifications-footer-heading {
          font-weight: 400;
          color: rgba(46, 46, 46, 0.3);
        }

        .date {
          font-weight: 600;
          color: #364a4e;
          margin: 0;
        }
      }

      .download-certificate-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 50px;
        background: #364a4e;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
      }

      .download-certificate-button:hover {
        background-color: #283f44;
      }
    }

    .kooledge-link-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      grid-column: 3;

      .certifications-footer-heading {
        font-weight: 600;
        color: #364a4e;
      }

      .link {
        font-weight: 400;
        color: rgba(46, 46, 46, 0.3);
        margin: 0;
      }
    }
  }
}

.my-certificate-wrapper.topic {
  background: #ebfaff;
}

.my-certificate-wrapper.flow {
  background: #fff7f0;
}
