.social-share-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 40px;

  .close-modal-icon {
    position: absolute;
    font-size: 2.2rem;
    color: #d74941;
    background-color: #fff;
    border-radius: 50%;
    top: -15px;
    right: -15px;
    z-index: 1;
    cursor: pointer;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 0 30px;

    .header-text {
      font-size: 1.75rem;
      font-weight: 700;
      text-align: center;
      color: #000;
      margin: 0;
    }

    .header-sub-text {
      text-align: center;
      font-size: 0.875rem;
      font-weight: 400;
      color: #8e8e8e;
      margin: 10px 0 0;
    }
  }

  .modal-body {
    width: 100%;
    padding: 0;

    .social-share-body {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 20px;
      padding: 30px;
    }

    .copy-text-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 15px 20px;
      background: #f4f4f4;
      border-radius: 10px;

      .copy-text {
        font-size: 1.125rem;
        font-weight: 400;
        color: #707070;
        margin: 0 5px 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .copy-icon {
        font-size: 1rem;
        color: #707070;
        cursor: pointer;
      }
    }
  }
}

.social-share-modal {
  .modal-dialog {
    max-width: 500px;

    .modal-content {
      position: relative;
      border-radius: 10px;
    }
  }
}
