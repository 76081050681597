#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1b1b1b;
  opacity: 0.6;
  z-index: 90;
}

.navbar {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 18px 0;
  z-index: 9;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 45px;

    .navbar-logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10%;
    }

    .menu-icon-bars {
      display: none;
      font-size: 1.8rem;
      color: #1b1b1b;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1450px) {
  .navbar .navbar-container {
    .navbar-logo-wrapper {
      margin-right: 80px;
    }
  }
}

@media screen and (max-width: 1330px) {
  .navbar .navbar-container {
    .navbar-logo-wrapper {
      margin-right: 60px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .navbar .navbar-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1080px) {
  .navbar .navbar-container {
    .menu-icon-bars {
      display: block;
    }
  }
}
