.topic-card-expanded-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  padding: 20px;
  background-color: #ebfaff;
  border: 1px solid #b9b9b9;
  margin: 24px 0;
  position: relative;

  .topic-card-expanded-row-1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .topic-card-expanded-title-and-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;

      .topic-card-expanded-title-textarea-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .topic-card-expanded-title-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: start;
          font-size: 1.5rem;
          font-weight: 700;
          color: #000;
          resize: none;
          height: 43px;
          width: 100%;
          padding: 2px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .topic-card-expanded-title-textarea:focus {
          background: rgba(48, 190, 238, 0.15);
          border: 1px solid #92e3ff;
        }

        .collapse-detail-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #c4c4c4;
          font-size: 0.9rem;
          height: auto;
          margin: 0 40px 0 30px;
          cursor: pointer;
          transition: 0.2s ease-in-out;
        }

        .collapse-detail-icon-rotate {
          transform: rotate(180deg);
        }

        .topic-expand-buttons-wrapper {
          display: grid;
          justify-content: center;
          align-items: center;
          grid-auto-flow: column;
          column-gap: 20px;

          .share-topic-button,
          .suggest-video-button,
          .topic-collapse-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 47px;
            padding: 0 24px;
            height: 48px;
            white-space: nowrap;
            cursor: pointer;

            .topic-collapse-icon {
              color: #fff;
            }

            .share-topic-button-text,
            .suggest-video-button-text,
            .topic-collapse-button-text {
              font-size: 0.875rem;
              color: #fff;
              text-align: center;
              padding-left: 12px;
              margin: 0;
            }
          }

          .share-topic-button {
            background-color: #30beee;
          }

          .suggest-video-button {
            background-color: #364a4e;
          }

          .topic-collapse-button {
            background-color: #f9a159;
          }

          .share-topic-button:hover {
            background-color: #11b7ee;
          }

          .suggest-video-button:hover {
            background-color: #283f44;
          }

          .topic-collapse-button:hover {
            background-color: #fa9746;
          }
        }
      }

      .topic-card-expanded-details {
        width: 100%;

        .topic-card-expanded-details-description-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: start;
          font-size: 0.875rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.9);
          resize: none;
          height: 72px;
          width: 70%;
          padding: 3px 6px 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
        }

        .topic-card-expanded-details-description-textarea:focus {
          background: rgba(48, 190, 238, 0.15);
          border: 1px solid #92e3ff;
        }

        .MuiFormControlLabel-root {
          margin: 8px 0;

          .MuiButtonBase-root {
            padding: 0;

            .MuiIconButton-label {
              width: 16px;
              height: 16px;

              .MuiSvgIcon-root {
                width: 16px;
                height: 16px;
              }
            }
          }

          .MuiFormControlLabel-label {
            font-size: 0.875rem;
            font-weight: 400;
            color: rgba(27, 27, 27, 0.6);
            padding: 0 0 0 10px;
          }
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: #30beee;
        }

        .topic-card-expanded-details-tags-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          .topic-card-expanded-details-no-tags {
            margin: 0 8px 0 0;
          }

          .topic-card-expanded-details-add-tag-button {
            display: flex;
            align-items: center;
            padding: 5px 9px;
            margin: 6px 0 0 0 !important;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.53);
            cursor: pointer;

            .add-icon {
              font-size: 0.9rem;
              color: #fff;
              margin-right: 8px;
            }

            .topic-card-expanded-details-add-tag-button-text {
              font-size: 0.875rem;
              font-weight: 400;
              color: #fff;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .topic-card-expanded-row-2 {
    width: 100%;
    margin: 13px 0;
  }

  .topic-card-expanded-row-3 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .topic-video-title-and-details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 7px 0 0 0;
      flex: 1;

      .topic-video-title-textarea-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;

        .topic-video-title-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: start;
          flex: 1;
          font-size: 1.125rem;
          font-weight: 600;
          color: #000;
          resize: none;
          height: 33px;
          padding: 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
        }

        .topic-video-title-textarea:focus {
          background: rgba(48, 190, 238, 0.15);
          border: 1px solid #92e3ff;
        }

        .topic-video-details-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 40px 0 30px;
          cursor: pointer;

          .topic-video-details-icon-text {
            font-size: 1rem;
            color: rgba(27, 27, 27, 0.6);
            margin: 0;
            padding-right: 10px;
          }

          .collapse-detail-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #c4c4c4;
            font-size: 0.8rem;
            margin-top: 2px;
            height: auto;
            transition: 0.2s ease-in-out;
          }

          .collapse-detail-icon-rotate {
            transform: rotate(180deg);
          }
        }
      }

      .topic-video-details {
        width: 100%;

        .topic-video-description-textarea {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: start;
          font-size: 0.875rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.9);
          resize: none;
          height: unset;
          width: 60%;
          padding: 0;
          border: none;
          outline: none;
          background-color: transparent;
          border: 1px solid transparent;
          box-sizing: border-box;
          margin: 0 0 5px 0;
        }

        .topic-video-description-textarea:focus {
          background: rgba(48, 190, 238, 0.15);
          border: 1px solid #92e3ff;
        }

        .topic-video-owner-and-tags-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .topic-video-owner-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: 6px 0 11px;

            .user-icon {
              font-size: 1.2rem;
              color: #364a4ee1;
            }

            .topic-video-owner-name {
              font-size: 1.125rem;
              font-weight: 400;
              color: #364a4e;
              margin: 0;
              padding-left: 11px;
            }
          }

          .topic-video-tags-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 10px;

            .topic-video-no-tags {
              margin: 0 8px 0 0;
            }

            .topic-video-add-tag-button {
              display: flex;
              align-items: center;
              padding: 5px 9px;
              margin: 6px 0 0 0 !important;
              border-radius: 3px;
              background: rgba(0, 0, 0, 0.53);
              cursor: pointer;

              .add-icon {
                font-size: 0.9rem;
                color: #fff;
                margin-right: 8px;
              }

              .topic-video-add-tag-button-text {
                font-size: 0.875rem;
                font-weight: 400;
                color: #fff;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .topic-video-stats-wrapper {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 20px;

      .share-topic-video-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #30beee;
        border-radius: 47px;
        height: 48px;
        padding: 0 24px;
        white-space: nowrap;
        cursor: pointer;

        .share-topic-video-button-text {
          font-size: 0.875rem;
          color: #fff;
          text-align: center;
          padding-left: 12px;
          margin: 0;
        }
      }

      .share-topic-video-button:hover {
        background-color: #11b7ee;
      }
    }
  }

  .topic-card-expanded-row-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 5px;

    .topic-card-tab-labels-wrapper {
      display: grid;
      justify-content: flex-start;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 24px;
      cursor: pointer;

      .topic-card-tab-label {
        font-size: 1.125rem;
        font-weight: 400;
        color: #000;
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
      }

      .topic-card-tab-selected {
        border-bottom: 2px solid #f9a159;
        cursor: pointer;
      }
    }

    .topic-card-browse-videos-panel,
    .topic-card-browse-quizzes-panel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: visible;
      width: 100%;
      min-height: 239px;
      max-height: 318px;
    }

    .topic-card-notes-panel {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-height: 155px;
      max-height: 400px;
      margin-top: 18px;

      .topic-card-notes-grid {
        display: grid;
        justify-content: flex-start;
        align-items: flex-start;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 15px;
        width: 100%;
        overflow: auto;

        .video-note-wrapper {
          justify-self: flex-start;

          .note-icon {
            cursor: pointer;
          }

          .note-title {
            cursor: pointer;
          }

          .remove-icon {
            display: none;
          }
        }
      }

      .no-notes {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1rem;
        margin: 0 0 17px 0;
      }
    }

    .topic-card-comments-panel {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      min-height: 155px;
      max-height: 400px;
      margin-top: 18px;

      .new-comment-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 37px;
        min-height: 37px;
        min-width: 136px;
        padding: 0 16px;
        font-size: 0.875rem;
        font-weight: 500;
        color: #364a4e;
        background: rgba(249, 249, 249, 0.9);
        border: 1px solid #364a4e;
        border-radius: 100px;
        box-sizing: border-box;
        white-space: nowrap;
        margin-bottom: 15px;
      }

      .new-comment-button:hover {
        background: #fff;
      }

      .topic-card-comments-grid {
        display: grid;
        justify-content: flex-start;
        align-items: flex-start;
        grid-template-columns: 100%;
        grid-auto-flow: row;
        width: 100%;
        padding-right: 20px;
        overflow: auto;

        .infinite-scroll-component__outerdiv {
          width: 100%;
        }
      }

      .no-comments {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1rem;
        margin: 17px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .topic-card-expanded-wrapper {
    .topic-card-expanded-row-1 {
      flex-direction: column;
      justify-content: flex-start;

      .topic-card-expanded-title-and-details-wrapper {
        width: 100%;
        margin-right: 0;

        .topic-card-expanded-title-textarea-wrapper {
          .collapse-detail-icon {
            margin: 0 0 0 30px;
          }
        }
      }

      .topic-expand-buttons-wrapper {
        margin-top: 20px;
      }
    }

    .topic-card-expanded-row-3 {
      flex-direction: column-reverse;

      .topic-video-title-and-details-wrapper {
        width: 100%;
        margin-top: 10px;

        .topic-video-title-textarea-wrapper {
          width: 100%;

          .topic-video-details-icon {
            margin: 0 0 0 30px;
          }
        }

        .topic-video-details {
          .topic-video-description-textarea {
            width: 100%;
          }
        }
      }

      .topic-video-stats-wrapper {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
