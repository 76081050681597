.home-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 30%;
  margin-top: 18px;
  width: 100%;

  .home-banner-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .CarouselItem {
      width: 100%;
      height: 100%;
    }

    .MuiButtonBase-root {
      top: calc(50% - 40px) !important;
      margin: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0;
      padding: 25px 5px;
    }
  }
}
