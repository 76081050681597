.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  margin-top: 84px;

  .spinner {
    color: #30beee;
  }

  .custom-loading-message {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin: 15px 0 0;
  }
}
