.quiz-answer-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  .create-panel-answer-input {
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(27, 27, 27, 0.9);
    margin: 8px 40px 0 0;
  }

  .answer-radio {
    .MuiFormControlLabel-root {
      align-items: flex-end;
      margin: 0;

      .MuiIconButton-root {
        padding-bottom: 4px;
      }

      .MuiFormControlLabel-label {
        font-size: 0.875rem;
        color: rgba(27, 27, 27, 0.6);
        white-space: nowrap;
      }
    }
  }

  .delete-answer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;

    .trash-icon {
      font-size: 1.1rem;
      color: #767676;
    }

    .delete-text {
      font-size: 0.875rem;
      color: rgba(27, 27, 27, 0.6);
      white-space: nowrap;
      margin: 0 0 0 10px;
    }
  }
}
