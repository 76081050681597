.sign-in-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 24px;
  height: 48px;
  border-radius: 47px;
  border: 2px solid #364a4e;
  background-color: #fff;
  cursor: pointer;

  h3 {
    color: #364a4e;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
}

.sign-in-button-wrapper:hover {
  background-color: #364a4e;

  h3 {
    color: #fff;
  }
}

.sign-up-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 24px;
  height: 48px;
  margin-right: 16px;
  border-radius: 47px;
  background-color: #364a4e;
  cursor: pointer;

  h3 {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
}

.sign-up-button-wrapper:hover {
  background-color: #283f44;
}
