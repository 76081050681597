.create-flow-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 125px;

  .create-flow-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1240px;
    width: 100%;
    max-width: 1140px;
    margin: 20px 0 50px;

    .create-flow-button,
    .cancel-flow-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      color: #364a4e;
      border: 1px solid #000;
      height: 74px;
      width: 49%;
      background-color: transparent;
      cursor: pointer;
    }

    .create-flow-button:hover {
      background-color: #364a4e;
      color: #fff;
      border: none;
    }

    .cancel-flow-button:hover {
      background-color: #d74941;
      color: #fff;
      border: none;
    }
  }
}

@media screen and (max-width: 1300px) {
  .create-flow-page-container {
    padding: 0 20px;
  }
}
