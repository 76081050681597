.search-flows-page-container {
  margin-top: 84px;
  min-height: 70vh;

  .search-flows-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 75vh;

    .search-flows-heading-and-results-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      width: 100%;

      .heading-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 26px 0 24px;

        .search-results-heading {
          font-size: 1.25rem;
          font-weight: 700;
          color: #000;
          margin: 0;
        }

        .search-results-count-text {
          font-size: 1rem;
          font-weight: 400;
          color: rgba(27, 27, 27, 0.6);
          margin: 0;
        }
      }

      .search-flows-list-grid {
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 30px;
        row-gap: 40px;
        margin-bottom: 60px;
        width: 100%;
      }
    }

    .search-flows-pagination {
      margin-bottom: 40px;

      .MuiPaginationItem-root {
        width: 40px;
        height: 40px;
        border-radius: 3.33333px;
        border: 0.833333px solid #364a4e;
      }

      .MuiPaginationItem-page.Mui-selected {
        background-color: #364a4e;
        color: #fff;
        border: 0.833333px solid #364a4e;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .search-flows-page-container {
    padding: 0 40px;
  }
}

@media screen and (max-width: 1100px) {
  .search-flows-page-container {
    .search-flows-page {
      .search-flows-heading-and-results-wrapper {
        .search-flows-list-grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .search-flows-page-container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 800px) {
  .search-flows-page-container {
    .search-flows-page {
      .search-flows-heading-and-results-wrapper {
        .search-flows-list-grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .search-flows-page-container {
    .search-flows-page {
      .search-flows-heading-and-results-wrapper {
        .search-flows-list-grid {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
