.topic-content-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;

  .svg-inline--fa.fa-w-16 {
    width: unset;
  }

  .topic-content-card-thumbnail {
    width: 100%;
    height: 150px;
    cursor: pointer;

    .topic-content-card-thumbnail-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .play-icon {
        font-size: 1.5rem;
        color: #fff;
      }
    }

    .topic-content-card-make-featured-overlay {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.6);

      .make-featured-icon {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
      }

      .topic-content-card-make-featured-text {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        margin: 0;
        padding-left: 6px;
      }
    }
  }

  .topic-content-card-thumbnail:hover {
    .topic-content-card-thumbnail-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .take-quiz-icon {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
      }

      .topic-content-card-take-quiz-text {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        margin: 0;
        padding-left: 6px;
      }
    }

    .topic-content-card-make-featured-overlay {
      display: flex;
    }
  }

  .topic-content-card-title-and-stats-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 12px;
    width: 100%;
    flex: 1;

    .topic-content-card-title-row {
      display: grid;
      justify-content: space-between;
      align-items: center;
      grid-auto-flow: column;
      column-gap: 15px;
      width: 100%;

      .topic-content-card-title {
        font-size: 1.375rem;
        font-weight: 700;
        color: #000;
        width: 100%;
        text-align: start;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .topic-content-card-video-stats {
      margin-top: 15px;

      .video-owner-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .card-stats-icon {
          font-size: 1rem;
          color: #364a4ed5;
        }

        .owner-name {
          font-size: 1rem;
          font-weight: 400;
          color: #364a4e;
          margin: 0;
          padding-left: 6px;
          white-space: nowrap;
        }
      }
    }
  }
}
